import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import api from "../../api/api";
import axios from "axios";
import Papa from "papaparse";

import { Scrollbars } from 'react-custom-scrollbars';

import {
  DndContext,
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  closestCenter
} from "@dnd-kit/core";
import {
  useSortable,
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from "@dnd-kit/utilities";

import { TextField, Radio, Box, Button, List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, TableSortLabel,Paper, LinearProgress, Alert, IconButton, Snackbar } from "@mui/material";
import { CircularProgress, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Menu, MenuList, MenuItem, Tabs, Tab, Stepper, Step, StepLabel, Typography, Popper } from "@mui/material";
import { Select, Checkbox, Drawer, Grow, ClickAwayListener, Avatar, Skeleton } from "@mui/material";
import { styled } from '@mui/material/styles';

import EditIconNewSvg from '../../assets/svg/EditIconNew.svg';
import DeleteIconNewSvg from '../../assets/svg/DeleteIconNew.svg';
import PlayIcon from '../../assets/svg/PlayIcon.svg';
import MakeInterviewUnique from '../../assets/svg/MakeInterviewUnique.svg';
import CreateSession from '../../assets/svg/CreateSession.svg';
import CallToApplyBg from '../../assets/svg/calltoapply-bg.svg';
import { 
  Add, ArrowForward, Assignment, AutoAwesomeOutlined, Circle,
  Clear, CloseFullscreen, ContentCopyOutlined, Delete, DragHandle,
  ExpandMore, FileDownload, FileUpload, KeyboardArrowDown, Logout,
  MoreVert, NavigateBefore, NavigateNext, OpenInFull, PeopleOutlined,
  Phone, PlayArrow, Settings, Share, UnfoldMore
} from "@mui/icons-material";

import { SECTIONS_OPTIONS, proxyEndpoint, SessionStatus, SessionStatusString } from "../../util/constants";

import CreateInterviewJD from "../../pages/CreateInterviewJD";

import TooltipCustom from "../common/TooltipCustom";
import ChipTag from "../common/ChipTag";
import FormField from "../common/FormField";
import FormSelect from "../common/FormSelect";
import TableSection from "../table/TableSection";
import SimulatedPreview from "../interview/SimulatedPreview";
import VoiceSelector from "../interview/VoiceSelector";
import { Sidebar } from "../nav/Sidebar";

import { buildUniqueSessionLink } from "../../util/interviews";

const getCompletedSessions = (id) => {
  return axios
    .post(proxyEndpoint + "/get-completed-sessions", { interviewId: id })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

const RadioBtn = ({ value, setValue, expectedValue, label }) => {
  return (
    <div className="radio-btn" style={{
      borderRadius: 12,
      height: '70px',
      width: '245px',
      padding: '12px',
      border: value === expectedValue ? '1px solid black' : '1px solid #CDCDCD'
    }}
    onClick={() => {
      setValue(expectedValue)
    }}>
      <div style={{ marginTop: '5px' }}>
        <Radio
          checked={value === expectedValue}
          onChange={() => {setValue(expectedValue)}}
          value={expectedValue}
        />
        <span style={{ display: 'inline-block', fontSize: '14px', fontWeight: 500  }}>
          {label}
        </span>
      </div>
    </div>
  )
}

const RowDragHandleCell = ({ rowId }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  })

  // console.log(rowId)

  return (
    <IconButton {...attributes} {...listeners} style={{ height: 'fit-content' }}>
      <DragHandle />
    </IconButton>
  )
}


const createUniqueInterviewSession = (interviewConfigId, candidateFirstName, candidateLastName, candidateEmail, candidatePhone, callType, userId, teamId, sessionSpecificQuestions) => {
  return axios.post(proxyEndpoint + '/sessions', { interviewConfigId, candidateFirstName, candidateLastName, candidateEmail, candidatePhone, callType, userId, teamId, sessionSpecificQuestions })
  .then(response => {
    return response.data;
  })
  .catch(err => {
    console.error(err);
  });
}

const InterviewDetailDraggableRow = ({ idx, question, questions, setCurrentQuestion, setShowAddQuestionInput, setQuestionDeletionId, setQuestionDeletionIndex, setShowQuestionDeletionConfirmation }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: question.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  return (
    <TableRow
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
        border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
    >
      <TableCell>
        <RowDragHandleCell rowId={question.id}/>
      </TableCell>
      <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', marginTop: '10px', fontWeight: 600, fontSize: '14px' }}>
        {question.question}
      </TableCell>
      <TableCell sx={{ padding: '0px', margin: '0px', paddingLeft: '17px' }}>{question.follow_ups}</TableCell>
      <TableCell sx={{ padding: '0px', margin: '0px', marginLeft: '40px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', marginBottom: 'auto' }}>
          {/* Edit */}
          <IconButton
            onClick={() => {
              setCurrentQuestion({
                text: question.question,
                numFollowUps: question.follow_ups,
                // section: question.section,
                editIndex: questions.findIndex((q) => q.id == question.id),
              })
              setShowAddQuestionInput(true)
            }}
            className="drop-shadow-btn"
            style={{ marginLeft: '15px', color: 'black',fontSize: '14px', fontWeight: 500, marginRight: '8px', borderRadius: '9px', border: '0.5px solid lightgray', padding: '6px 9px 6px 8px' }}
          >
            <img src={EditIconNewSvg} /> Edit
          </IconButton>
          {/* Delete */}
          <IconButton
            disabled={questions.length === 1}
            onClick={() => {
              setQuestionDeletionId(question.id);
              setShowQuestionDeletionConfirmation(true);
            }}
            className="drop-shadow-btn"
            style={{ background: '#FFF8F8', color: '#F33D3D',fontSize: '14px', fontWeight: 500, marginRight: '4px', borderRadius: '9px', border: '0.5px solid lightgray', padding: '6px 9px 6px 8px' }}
          >
            <img src={DeleteIconNewSvg} /> Delete
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

const SessionSpecificDraggableRow = ({ idx, row, stateAddQuestions, setAddQuestions }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  return (
    <TableRow
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
        border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
    >
      <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', padding: '0px', margin: '8px' }}>
        <RowDragHandleCell rowId={row.id}/>
        {(stateAddQuestions.currentQuestion && stateAddQuestions.currentQuestion.id === row.id) ?
          <textarea
            style={{ fontSize: '11px', padding: '10px', height: '75px', width: '100%', borderRadius: 6, border: '1px solid lightgray', resize: 'none', fontFamily: 'PPNeueMontreal' }}
            value={row.question}
            onChange={(e) => {
              let newStateAddQuestions = JSON.parse(JSON.stringify(stateAddQuestions))
              newStateAddQuestions.questions[idx].question = e.target.value
              setAddQuestions({
                ...newStateAddQuestions,
                currentQuestion: {
                  text: e.target.value,
                  ...stateAddQuestions.currentQuestion
                }
              })
            }}
          />
        :
          <div style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '8px', marginLeft: '8px', fontFamily: 'PPNeueMontreal', fontSize: '11px' }}>{row.question}</div>
        }

      </TableCell>
      <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>
      {(stateAddQuestions.currentQuestion && stateAddQuestions.currentQuestion.id === row.id) ?
        <TextField value={row.follow_ups} type="number" onChange={(e) => {
          let newStateAddQuestions = JSON.parse(JSON.stringify(stateAddQuestions))
          newStateAddQuestions.questions[idx].follow_ups = e.target.value
          setAddQuestions({
            ...newStateAddQuestions,
            currentQuestion: {
              numFollowUps: e.target.value,
              ...stateAddQuestions.currentQuestion
            }
          })
        }} />
        : row.follow_ups}
      </TableCell>
        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px', marginRight: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', marginBottom: 'auto' }}>
            {/* Edit */}
            <IconButton
              onClick={() => {
                setAddQuestions({
                  ...stateAddQuestions,
                  currentQuestion: {
                    text: row.question,
                    numFollowUps: row.follow_ups,
                    id: row.id,
                    editIndex: idx,
                  },
                });
              }}
              style={{ color: '#656565', marginRight: '4px' }}
            >
              <img src={EditIconNewSvg} />
            </IconButton>
            {/* Delete */}
            <IconButton
              onClick={() => {
                let newQuestions = stateAddQuestions.questions.filter((_, questionIdx) => questionIdx !== idx);
                setAddQuestions({ ...stateAddQuestions, questions: newQuestions });
              }}
              style={{ color: '#656565' }}
            >
              <img src={DeleteIconNewSvg} />
            </IconButton>
          </div>
        </TableCell>
    </TableRow>
  )
}

const updatePrompt = (interviewConfigId, prompt) => {
  return axios.post(proxyEndpoint + '/interview-configs/prompts', { interviewConfigId, prompt })
  .then(response => {
      console.log(response.data)
      return response.data
  })
  .catch(err => {
      console.error(err)
  })
}

const updateEvalPrompt = (interviewConfigId, prompt) => {
  return axios.post(proxyEndpoint + '/interview-configs/eval-prompts', { interviewConfigId, prompt })
  .then(response => {
      console.log(response.data)
      return response.data
  })
  .catch(err => {
      console.error(err)
  })
}

function InterviewDetail({ importedInterviews }) {
  const navigate = useNavigate();
  const ANALYSIS_ACCESS = [
    "miguelpatricktaruc@gmail.com",
    "varun@techinterviewer.ai",
    "shreyas@techinterviewer.ai",
    "shreyas.jaganmohan@gmail.com",
    "omkar@wayfaster.com",
    "jshrivastav@instawork.com",
    "mshukr@instawork.com",
    "shreyas@shreyasj.com",
    "talha@tokenmetrics.com",
    "obaid@tokenmetrics.com",
    "mlaken@atlantic-grp.com",
  ];


  const thumbVDark = ({ style, ...props }) => (
    <div
      style={{
        ...style,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderRadius: "10px",
        cursor: "pointer",
      }}
      {...props}
    />
  );
  
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const { interview_id } = useParams();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const [interview, setInterview] = useState({});
  const [interviews, setInterviews] = useState(
    importedInterviews ? importedInterviews : []
  );
  const [previousInterviewId, setPreviousInterviewId] = useState("");
  const [nextInterviewId, setNextInterviewId] = useState("");
  const [navigatedLoading, setNavigatedLoading] = useState(false);
  const [getInterviewCalled, setGetInterviewCalled] = useState(false);
  const [getInterviewReturned, setGetInterviewReturned] = useState(false);
  const [completedSessions, setCompletedSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [sessionFilter, setSessionFilter] = useState(null);
  const [getCompletedSessionsCalled, setGetCompletedSessionsCalled] =
    useState(false);
  const [showStructuredOutputs, setShowStructuredOutputs] = useState(false);
  const [showScorecard, setShowScorecard] = useState(false);
  const [currentStructuredOutputs, setCurrentStructuredOutputs] = useState([]);
  const [currentScorecard, setCurrentScorecard] = useState([]);
  const [currentScorecards, setCurrentScorecards] = useState([]);
  const [newPrompt, setNewPrompt] = useState("");
  const [newEvalPrompt, setNewEvalPrompt] = useState("");
  const [currentMessages, setCurrentMessages] = useState([]);
  const [showMessages, setShowMessages] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [currentSummary, setCurrentSummary] = useState("");
  const [sending, setSending] = useState(false);
  const [showCandidateLinkCreation, setShowCandidateLinkCreation] =
    useState(false);
  const [showBulkCandidateLinkCreation, setShowBulkCandidateLinkCreation] =
    useState(false);
  const [
    createBulkCandidateSessionActiveStep,
    setCreateBulkCandidateSessionActiveStep,
  ] = useState(0);
  const [bulkInviteType, setBulkInviteType] = useState("");
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [createSessionInputs, setCreateSessionInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    callType: "web",
  });
  const [file, setFile] = useState("");
  const [CSVData, setCSVData] = useState([]);
  const [interviewRequiresSave, setInterviewRequiresSave] = useState(false);
  const [uniqueSessionLink, setUniqueSessionLink] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [copied, setCopied] = useState(false);
  const [showVideoRecording, setShowVideoRecording] = useState(false);
  const [, setCurrentVideoRecording] = useState("");
  const [currentTab, setCurrentTab] = useState("sessions");
  const [uniqueSessionId, setUniqueSessionId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newInterviewTitle, setNewInterviewTitle] = useState("");
  const [newInterviewLength, setNewInterviewLength] = useState(10);
  const [sessionSpecificContext, setSessionSpecificContext] = useState("");
  const [errMsgs, setErrMsgs] = useState("");
  const [
    createCandidateSessionActiveStep,
    setCreateCandidateSessionActiveStep,
  ] = useState(0);
  const [editGeneratedQuestionsIndex, setEditGeneratedQuestionsIndex] =
    useState(-1);
  const [quickActionsAnchorEl, setQuickActionsAnchorEl] = useState(null);
  const [actionsOpenIdx, setActionsOpenIdx] = useState(-1);
  const [inProgressEl, setInProgressEl] = useState(null);
  const [rerunningIdxes, setRerunningIdxes] = useState([]);
  const [rerunningIds, setRerunningIds] = useState([]);
  const [runningBulk, setRunningBulk] = useState(false);
  const [sessionSpecificQuestionsStarted, setSessionSpecificQuestionsStarted] =
    useState(false);
  const [globalOverrideUser, setGlobalOverrideUser] = useState("");
  const [sections, setSections] = useState(SECTIONS_OPTIONS);
  const [newSectionName, setNewSectionName] = useState("");
  const [dynamicLink, setDynamicLink] = useState("");
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  // candidates
  const [candidates, setCandidates] = useState([]);
  const [getCandidatesCalled, setGetCandidatesCalled] = useState(false);

  // session deletion
  const [sessionToBeDeleted, setSessionToBeDeleted] = useState(null);
  const [showSessionDeletionConfirmation, setShowSessionDeletionConfirmation] =
    useState(false);

  // render settings
  const [currentVoice, setCurrentVoice] = useState({});
  const [voices, setVoices] = useState([]);

  // inbound outbound
  const [inboundOutbound, setInboundOutbound] = useState("outbound");
  const [showGeneratePhoneNumber, setShowGeneratePhoneNumber] = useState(false);
  const [generatingPhoneNumber, setGeneratingPhoneNumber] = useState(false);
  const [outbound, setOutbound] = useState({
    headline: "",
    message: "",
    branding: "",
  });

  // First Load: global override user
  useEffect(() => {
    const overrideUserLocal = localStorage.getItem("globalOverrideUser");
    if (
      globalOverrideUser !== undefined &&
      globalOverrideUser.length === 0 &&
      overrideUserLocal != null
    ) {
      setGlobalOverrideUser(overrideUserLocal);
    }
  }, [globalOverrideUser, setGlobalOverrideUser]);

  // First Load: dynamic link
  useEffect(() => {
    if (interview_id) {
      setDynamicLink(`https://beta.techinterviewer.ai/i/${interview_id}`);
    }
  }, [interview_id]);

  const handleCopyDynamicLink = () => {
    navigator.clipboard.writeText(dynamicLink);
    setShowCopySuccess(true);
    setTimeout(() => setShowCopySuccess(false), 2000);
  };

  // First Load: get interview and interview sessions
  // TODO: offload this, make interview detail a strict visual render component
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!importedInterviews && user && !getInterviewCalled) {
        let tmpId = interview_id;
        const teamId = localStorage.getItem("teamId");
        if (importedInterviews && importedInterviews.length > 0) {
          tmpId = importedInterviews[0]._id;
        }
        var userId = user.email;
        const overrideUserLocal = localStorage.getItem("globalOverrideUser");
        if (overrideUserLocal != null) {
          userId = overrideUserLocal;
        }
        // get interview
        setGetInterviewCalled(true); // avoid duplicate call by flagging before waiting for response
        api
        .getInterview(tmpId, userId, teamId)
        .then((response) => {
          setInterview(response.data);
          setNewInterviewTitle(response.data.title);
          setNewInterviewLength(response.data.length);
          setVideoRecorder(response.data.videoRecorder);
          setPracticeChatEnabled(response.data.practiceChatEnabled);
          refreshSections(response.data);
          setNewPrompt(response.data.prompt ? response.data.prompt : "");
          setNewEvalPrompt(
            response.data.eval_prompt ? response.data.eval_prompt : ""
          );

          // get voices
          api.getVoices().then((voice_res) => {
            let voices = voice_res.data.voices;
            setVoices(voices);

            let current_voice = response.data.voice
              ? response.data.voice
              : voices.find((voice) => voice.voice_id == "11labs-Jason");
            setCurrentVoice(current_voice);
          });
          setNavigatedLoading(false);
          setGetInterviewReturned(true);
        })
        .catch((err) => {
          console.error(err);
          setGetInterviewCalled(false);
        });
    
        // get completed sessions
        if (!importedInterviews && user && !getCompletedSessionsCalled) {
          getCompletedSessions(interview_id).then((response) => {
            setGetCompletedSessionsCalled(true);
            setCompletedSessions(response);
            handleSortCompleted(response, "desc");
            setCompletedOrder("desc");
            setNavigatedLoading(false);
          }).catch((err) => {
            console.error(err);
            setGetCompletedSessionsCalled(false);
          });
        }
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [
    interview,
    importedInterviews,
    getCompletedSessionsCalled,
    getInterviewCalled,
    interview_id,
    navigate,
    refreshSections,
    user,
    location.pathname,
    location.search,
  ]);

  useEffect(() => {
    if (interview._id) {
      api.getCallToApplyInfo({ interviewId: interview._id }).then((res) => { 
        setOutbound(res.data.outbound);
      });
    }
  }, [interview._id]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );
  const anchorRef = useRef(null);
  const moreActionsRef = React.useRef(null);

  const handleListShareToggle = (e, idx) => {
    e.preventDefault();
    e.stopPropagation();
    if (quickActionsAnchorEl == e.currentTarget) {
      handleListActionsClose(e);
    } else {
      setQuickActionsAnchorEl(e.currentTarget);
      setActionsOpenIdx(idx);
    }
  };

  const handleListActionsClose = (event) => {
    setQuickActionsAnchorEl(null);
    setActionsOpenIdx(-1);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setActionsOpenIdx(-1);
    } else if (event.key === "Escape") {
      setActionsOpenIdx(-1);
    }
  }

  // Questions page
  const [currentQuestion, setCurrentQuestion] = useState({
    text: "",
    numFollowUps: 0,
    // section: '',
    editIndex: -1,
  });
  const [showAddQuestionInput, setShowAddQuestionInput] = useState(false);
  const [
    showQuestionDeletionConfirmation,
    setShowQuestionDeletionConfirmation,
  ] = useState(false);
  const [questionDeletionIndex, setQuestionDeletionIndex] = useState(-1);
  const [questionDeletionId, setQuestionDeletionId] = useState("");

  // Scorecard page
  const [showAddSignalInput, setShowAddSignalInput] = useState(false);
  const [showAddSignalAssistInput, setShowAddSignalAssistInput] =
    useState(false);
  const [currentSignal, setCurrentSignal] = useState({
    "4PointCriteria": "",
    "3PointCriteria": "",
    "2PointCriteria": "",
    "1PointCriteria": "",
    weight: 1,
    scale: 4,
    signal: "",
    description: "",
    editIndex: -1,
  });
  const [generatedCriteria, setGeneratedCriteria] = useState({});
  const [signalDeletionIndex, setSignalDeletionIndex] = useState(-1);
  const [showSignalDeletionConfirmation, setShowSignalDeletionConfirmation] =
    useState(false);

  const [scoringGenerating, setScoringGenerating] = useState(false);
  const [scoringAssistStep, setScoringAssistStep] = useState(0);

  const [scoringAddTab, setScoringAddTab] = useState(0);
  const [scoringActiveCriteria, setScoringActiveCriteria] =
    useState("1PointCriteria");

  // Structured Outputs page
  const [showAddOutputInput, setShowAddOutputInput] = useState(false);
  const [currentOutput, setCurrentOutput] = useState({});
  const [outputDeletionIndex, setOutputDeletionIndex] = useState(-1);
  const [showOutputDeletionConfirmation, setShowOutputDeletionConfirmation] =
    useState(false);
  const [generatedSessionQuestions, setGenerateSessionQuestions] = useState([]);
  const [
    generateSessionSpecificQuestionsLoading,
    setGenerateSessionSpecificQuestionsLoading,
  ] = useState(false);

  // misc

  const [statusOrder, setStatusOrder] = useState("asc");
  const [completedOrder, setCompletedOrder] = useState("asc");
  const [importExportAnchorDropdownEl, setImportExportAnchorDropdownEl] =
    useState(null);

  // const [completedOrderSetOnPageLoad, setCompletedOrderSetOnPageLoad] = useState(false)

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (filteredSessions.length > 0 && completedSessions.length === filteredSessions.length && completedOrder === 'asc' && !completedOrderSetOnPageLoad) {
  //       handleSortCompleted();
  //       setCompletedOrder('desc');
  //       setCompletedOrderSetOnPageLoad(true)
  //     }
  //   }, 1000);
  // }, [filteredSessions, completedSessions, completedOrderSetOnPageLoad]);
  const [invitedOrder, setInvitedOrder] = useState("asc");
  const [scoreOrder, setScoreOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("status");
  const ref = useRef(null);

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    callType: false,
    inviteMethod: false,
  });
  const [inviteType, setInviteType] = useState("");
  const [callType, setCallType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [generatedCandidateSessionLink, setGeneratedCandidateSessionLink] =
    useState("");
  const [candidateEmailSent, setCandidateEmailSent] = useState(false);
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidatePhoneNumber, setCandidatePhoneNumber] = useState("");
  const [
    createUniqueInterviewSessionFailed,
    setCreateUniqueInterviewSessionFailed,
  ] = useState(false);
  const [sessionCreationActiveStep, setSessionCreationActiveStep] =
    useState(-1);
  const [creationFlow, setCreationFlow] = useState("");
  const [videoRecorder, setVideoRecorder] = useState("builtin");
  const [practiceChatEnabled, setPracticeChatEnabled] = useState(false);
  const [roleBackground, setRoleBackground] = useState({
    interviewTitle: "",
    assessmentLanguage: "English",
    jobTitle: "",
    interviewLength: 10,
  });

  // preview
  const [previewOpen, setPreviewOpen] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [currentPreviewQuestion, setCurrentPreviewQuestion] = useState({});
  const [interviewPreview, setInterviewPreview] = useState([]);

  const setSelectedPreview = async (question) => {
    setCurrentPreviewQuestion(question);
    if (question.sample) {
      setInterviewPreview(question.sample);
    } else {
      let sample = await callGenerateConversationPreview({
        topic: question.question,
        follow_ups: String(question.follow_ups),
        context_type: "question_preview",
        job_title: interview.jobTitle,
        title: interview.title,
      });
      if (sample) setInterviewPreview(sample);
      else setCurrentPreviewQuestion({});
      console.log("sample", sample);
      question = { ...question, sample };
      updateInQuestions(question.id, question);
    }
  };

  const updateInQuestions = async (questionId, updatedQuestion) => {
    let updatedQuestions = [...interview.questions];
    let updatedIndex = updatedQuestions.findIndex(
      (question) => question.id == questionId
    );
    if (updatedIndex >= 0) updatedQuestions[updatedIndex] = updatedQuestion;
    else updatedQuestions = [...updatedQuestions, updatedQuestion];
    setInterview({ ...interview, questions: updatedQuestions });
  };

  const callGenerateConversationPreview = ({
    topic,
    follow_ups,
    context_type,
    job_title,
    title,
  }) => {
    setLoadingPreview(true);
    return api
      .generateConversationPreview({
        topic,
        follow_ups,
        context_type,
        job_title,
        title,
      })
      .then((response) => {
        setLoadingPreview(false);
        return response.data.sample;
      })
      .catch((error) => {
        console.log(error);
        setLoadingPreview(false);
        return null;
      });
  };

  const truncate = (str) => {
    let max = 48;
    return str.length > max ? str.substring(0, max) + "..." : str;
  };
  const [showErr, setShowErr] = useState(false);

  const createCandidateSessionSteps = ["Session Details", "Specific Questions"];
  const [warnings, setWarnings] = useState([]);
  const createBulkCandidateSessionSteps = ["Upload CSV", "Sessions"];
  const [bulkCreating, setBulkCreating] = useState(false);

  const handleScoringAddTabChange = (event, newValue) => {
    // out of bounds, greater than previous scale
    let criteriaLabel = `${newValue + 1}PointCriteria`;
    if (!(criteriaLabel in currentSignal)) {
      setCurrentSignal({
        ...currentSignal,
        [criteriaLabel]: "", // init new criteria
      });
    }
    setScoringAddTab(newValue);
  };

  if (isLoading) {
    return (
      <CircularProgress
        sx={{ color: "var(--color-primary)", margin: "auto" }}
      />
    );
  }
  if (!isAuthenticated) {
    navigate("/");
  }

  const isCreateCandidateSessionStepOptional = (currentStep) => {
    if (currentStep === 0) {
      return true;
    }
    return false;
  };

  const isCreateCandidateSessionStepSkipped = (step) => {
    return false;
  };

  const handleGenerateSessionSpecificQuestions = () => {
    console.log("handleGenerateSessionSpecificQuestions");
    axios
      .post(`${proxyEndpoint}/generate_interview_questions`, {
        interview_config_id: interview_id,
        context: sessionSpecificContext,
        context_type: "session_specific",
      })
      .then((response) => {
        setGenerateSessionQuestions(response.data.questions);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const phoneNumIsCorrect = (phoneNum) => {
    const regex = /^\+1\d{10}$/;
    return regex.test(phoneNum);
  };

  const sessionDetails = () => {
    const validateForm = () => {
      let isValid = true;
      let errors = {
        firstName: false,
        lastName: false,
        email: false,
        phoneNumber: false,
        inviteMethod: false,
        callType: false,
      };

      if (!firstName.trim()) {
        errors.firstName = true;
        isValid = false;
      }

      if (!lastName.trim()) {
        errors.lastName = true;
        isValid = false;
      }

      if (inviteType === "email" && !validateEmail(candidateEmail)) {
        errors.email = true;
        isValid = false;
      }

      if (callType === "phone" && !phoneNumIsCorrect(candidatePhoneNumber)) {
        errors.phoneNumber = true;
        isValid = false;
      }

      if (inviteType === "") {
        errors.inviteMethod = true;
        isValid = false;
      }

      if (callType === "") {
        errors.callType = true;
        isValid = false;
      }

      setFormErrors(errors);
      return isValid;
    };

    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p
              style={{
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "PPNeueMontreal",
              }}
            >
              Create candidate session{" "}
            </p>
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontSize: "14px", fontFamily: "PPNeueMontreal" }}>
              {" "}
              Enter candidate first name
            </p>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <TextField
              value={firstName}
              placeholder={"Joe"}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              fullWidth={true}
              error={showErr && formErrors.firstName}
              helperText={
                showErr
                  ? formErrors.firstName
                    ? "First name is required"
                    : ""
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontSize: "14px", fontFamily: "PPNeueMontreal" }}>
              {" "}
              Enter candidate last name
            </p>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <TextField
              value={lastName}
              placeholder={"Smith"}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              fullWidth={true}
              error={showErr && formErrors.lastName}
              helperText={
                showErr
                  ? formErrors.lastName
                    ? "Last name is required"
                    : ""
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontSize: "14px", fontFamily: "PPNeueMontreal" }}>
              {" "}
              Call type{" "}
            </p>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <RadioBtn
              value={callType}
              setValue={setCallType}
              expectedValue={"web"}
              label={"Web"}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <RadioBtn
              value={callType}
              setValue={setCallType}
              expectedValue={"phone"}
              label={"Phone"}
            />
          </Grid>
          {callType === "phone" && (
            <>
              <Grid item xs={12}>
                <p style={{ fontSize: "14px", fontFamily: "PPNeueMontreal" }}>
                  {" "}
                  Candidate phone number
                </p>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <TextField
                  fullWidth={true}
                  style={{
                    outline: 0,
                    textDecoration: "none",
                    fontFamily: "PPNeueMontreal",
                    fontSize: "14px",
                  }}
                  value={candidatePhoneNumber}
                  type="text"
                  placeholder={"+15555555555"}
                  onChange={(e) => {
                    setCandidatePhoneNumber(e.target.value);
                  }}
                  error={formErrors.phoneNumber}
                  helperText={
                    formErrors.phoneNumber ? "Invalid phone number format" : ""
                  }
                />
              </Grid>
            </>
          )}
          {showErr && formErrors.callType && (
            <Grid item xs={12}>
              <p
                style={{
                  fontWeight: 400,
                  color: "#d32f2f",
                  fontSize: "12px",
                  fontFamily: "PPNeueMontreal",
                  margin: 0,
                  padding: 0,
                  marginBottom: "10px",
                }}
              >
                Select a call type.
              </p>
            </Grid>
          )}
          <Grid item xs={12}>
            <p style={{ fontSize: "14px", fontFamily: "PPNeueMontreal" }}>
              {" "}
              How do you want to send the invite?
            </p>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <RadioBtn
              value={inviteType}
              setValue={setInviteType}
              expectedValue={"session_link"}
              label={"Session link"}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <RadioBtn
              value={inviteType}
              setValue={setInviteType}
              expectedValue={"email"}
              label={"Send invitation via Email"}
            />
          </Grid>
          {inviteType === "email" && (
            <>
              <Grid item xs={12}>
                <p style={{ fontSize: "14px", fontFamily: "PPNeueMontreal" }}>
                  {" "}
                  Candidate Email{" "}
                </p>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <TextField
                  fullWidth={true}
                  style={{
                    outline: 0,
                    textDecoration: "none",
                    fontFamily: "PPNeueMontreal",
                    fontSize: "14px",
                  }}
                  value={candidateEmail}
                  onChange={(e) => {
                    setCandidateEmail(e.target.value);
                  }}
                  placeholder={"joe.smith@gmail.com"}
                  error={formErrors.email}
                  helperText={formErrors.email ? "Invalid email format" : ""}
                />
              </Grid>
            </>
          )}
          {showErr && formErrors.inviteMethod && (
            <Grid item xs={12}>
              <p
                style={{
                  fontWeight: 400,
                  color: "#d32f2f",
                  fontSize: "12px",
                  fontFamily: "PPNeueMontreal",
                  margin: 0,
                  padding: 0,
                  marginBottom: "10px",
                }}
              >
                Select an invite method.
              </p>
            </Grid>
          )}
          <Grid item xs={12} style={{ marginBottom: "100px" }}></Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                className="btn-inverted"
                style={{
                  fontSize: "14px",
                  padding: "10px 15px",
                  marginRight: "16px",
                }}
                onClick={() => {
                  setShowCandidateLinkCreation(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn-main"
                style={{
                  fontSize: "14px",
                  padding: "10px 15px",
                }}
                onClick={() => {
                  if (validateForm()) {
                    setCreateCandidateSessionActiveStep(
                      createCandidateSessionActiveStep + 1
                    );
                  } else {
                    setShowErr(true);
                  }
                }}
              >
                Next step{" "}
                <ArrowForward
                  style={{ fontSize: "14px", marginLeft: "8px" }}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  };

  const handleCreateCandidateSession = () => {
    /*
      interview_config_id = request.json.get('interviewConfigId')
      user_id = request.json.get('userId')
      unique_session_id = str(uuid.uuid4())
      candidate_first_name = request.json.get('candidateFirstName')
      candidate_last_name = request.json.get('candidateLastName')
      candidate_email = request.json.get('candidateEmail')
      candidate_phone = request.json.get('candidatePhone')
      call_type = request.json.get('callType')
      session_specific_questions = request.json.get('sessionSpecificQuestions', [])
    */
    var userId = user.email;
    const overrideUserLocal = localStorage.getItem("globalOverrideUser");
    if (overrideUserLocal != null) {
      userId = overrideUserLocal;
    }
    const teamId = localStorage.getItem("teamId");

    const payload = {
      interviewConfigId: interview._id,
      userId: userId,
      candidateFirstName: firstName,
      candidateLastName: lastName,
      candidateEmail: candidateEmail,
      candidatePhone: candidatePhoneNumber,
      callType: callType,
      sessionSpecificQuestions: generatedSessionQuestions,
      teamId: teamId,
    };


    createUniqueInterviewSession(
      payload.interviewConfigId,
      payload.candidateFirstName,
      payload.candidateLastName,
      payload.candidateEmail,
      payload.candidatePhone,
      payload.callType,
      payload.userId,
      payload.teamId,
      payload.sessionSpecificQuestions
    )
      .then((response) => {
        if (payload.candidateEmail.length > 0) {
          setCandidateEmailSent(true);
        }
        if (payload.candidatePhone.length > 0) {
          setGeneratedCandidateSessionLink(
            "https://beta.techinterviewer.ai/phone?session=" + response
          );
        } else {
          setGeneratedCandidateSessionLink(
            "https://beta.techinterviewer.ai/?session=" + response
          );
        }
        // refresh sessions list
        setGetCompletedSessionsCalled(false);
      })
      .catch((response) => {
        console.log("failed to make request", response);
        setCreateUniqueInterviewSessionFailed(true);
      });
  };

  const sessionSpecificQuestions = () => {
    console.log("generatedSessionQuestions", generatedSessionQuestions);
    if (sessionSpecificQuestionsStarted) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {(generatedSessionQuestions.questions === undefined ||
              generatedSessionQuestions.questions.length === 0) && (
              <CreateInterviewJD
                setCreationFlow={setCreationFlow}
                setActiveStep={setSessionCreationActiveStep}
                setAddQuestions={setGenerateSessionQuestions}
                setShowAddQuestionInput={setShowAddQuestionInput}
                setRoleBackground={setRoleBackground}
                roleBackground={roleBackground}
                fullWidth={true}
                btnText={"Generating questions"}
                showInterviewSetup={false}
                headerText={"Enter session-specific information"}
                label={"Session-Specific Information"}
              />
            )}
            {generatedSessionQuestions.questions &&
              generatedSessionQuestions.questions.length > 0 && (
                <div>
                  <Paper
                    style={{
                      minHeight: "400px",
                      padding: "25px",
                      margin: "auto",
                      marginTop: "24px",
                      width: "100%",
                      boxShadow: "none",
                      borderRadius: "6px",
                      border: "1px solid #D9D9D9",
                    }}
                  >
                    <h3 className="section-heading" style={{ marginTop: 0 }}>
                      Session-specific Questions
                    </h3>
                    <Grid container>
                      <TableContainer
                        style={{ padding: 0, width: "100%", margin: 0 }}
                      >
                        <Table style={{ padding: 0, margin: 0, width: "100%" }}>
                          <TableHead
                            style={{ padding: 0, margin: 0, width: "100%" }}
                          >
                            <TableRow
                              style={{ padding: 0, margin: 0, width: "100%" }}
                            >
                              <TableCell
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  width: "100%",
                                  fontSize: 14,
                                  color: "#656565",
                                  border: "none",
                                  fontWeight: 500,
                                }}
                              >
                                Question
                              </TableCell>
                              <TableCell
                                style={{
                                  display: "flex",
                                  minWidth: "80px",
                                  padding: "4px",
                                  margin: 0,
                                  fontSize: 14,
                                  color: "#656565",
                                  border: "none",
                                  fontWeight: 500,
                                  width: "72px",
                                }}
                              >
                                Follow-ups
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: 16,
                                  color: "#656565",
                                  border: "none",
                                }}
                              >
                                {/* Actions */}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <SortableContext
                              items={generatedSessionQuestions.questions.map(
                                (question, idx) => {
                                  return question.id;
                                }
                              )}
                              strategy={verticalListSortingStrategy}
                            >
                              {generatedSessionQuestions.questions.map(
                                (question, idx) => (
                                  <SessionSpecificDraggableRow
                                    idx={idx}
                                    row={question}
                                    setAddQuestions={
                                      setGenerateSessionQuestions
                                    }
                                    setShowAddQuestionInput={
                                      setShowAddQuestionInput
                                    }
                                    stateAddQuestions={
                                      generatedSessionQuestions
                                    }
                                  />
                                )
                              )}
                            </SortableContext>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* <button className="btn-main d-flex" style={{ marginTop: '16px' }}
                    onClick={() => {triggerShowAddModal('question')}}>
                    <Add className="my-auto" fontSize="24px"/>
                    Add Question
                  </button>     */}
                    </Grid>
                  </Paper>
                </div>
              )}
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "100px" }}></Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                className="btn-inverted"
                style={{
                  fontSize: "14px",
                  padding: "10px 15px",
                  marginRight: "16px",
                }}
                onClick={() => {
                  setShowCandidateLinkCreation(false);
                  setCreateCandidateSessionActiveStep(0);
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn-main"
                style={{
                  fontSize: "14px",
                  padding: "10px 15px",
                }}
                onClick={() => {
                  setCreateCandidateSessionActiveStep(
                    createCandidateSessionActiveStep + 1
                  );
                  handleCreateCandidateSession();
                }}
              >
                Next step{" "}
                <ArrowForward
                  style={{ fontSize: "14px", marginLeft: "8px" }}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img style={{ marginLeft: "5%" }} src={MakeInterviewUnique} />
          </Grid>
          <Grid item xs={12}>
            <IconButton
              className="btn-main"
              style={{
                marginLeft: "225px",
                marginTop: "25px",
                fontSize: "14px",
                paddingTop: "15px",
                paddingBottom: "15px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
              onClick={() => {
                setSessionSpecificQuestionsStarted(true);
              }}
            >
              <img src={PlayIcon} /> &nbsp;&nbsp;Let's start
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                color: "#70707B",
                fontSize: "16px",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              This step is not required.
            </h3>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "100px" }}></Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                className="btn-inverted"
                style={{
                  fontSize: "14px",
                  padding: "10px 15px",
                  marginRight: "16px",
                }}
                onClick={() => {
                  setShowCandidateLinkCreation(false);
                  setCreateCandidateSessionActiveStep(0);
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn-main"
                style={{
                  fontSize: "14px",
                  padding: "10px 15px",
                }}
                onClick={() => {
                  setCreateCandidateSessionActiveStep(
                    createCandidateSessionActiveStep + 1
                  );
                  handleCreateCandidateSession();
                }}
              >
                Next step{" "}
                <ArrowForward
                  style={{ fontSize: "14px", marginLeft: "8px" }}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      );
    }
  };

  const uploadBulkCSV = () => {
    const VisuallyHiddenInput = styled("input")({
      clip: "rect(0 0 0 0)",
      clipPath: "inset(50%)",
      height: 1,
      overflow: "hidden",
      position: "absolute",
      bottom: 0,
      left: 0,
      whiteSpace: "nowrap",
      width: 1,
    });

    const readCSV = (data) => {
      console.log(data);
      const headerRow = [
        "First",
        "Last",
        "Phone #",
        "Email",
        "Worker ID",
        "Shift ID",
      ];

      const isHeaderRow = (row) => {
        if (row.length !== 6) {
          return false;
        }

        var headerRowFound = true;
        for (let elIdx = 0; elIdx < row.length; elIdx++) {
          let el = row[elIdx];
          if (el !== headerRow[elIdx]) {
            headerRowFound = false;
          }
        }

        return headerRowFound;
      };

      var currentWarnings = [];
      var candidateValids = data.map((row, idx) => {
        console.log(row);
        const humanIdx = idx + 1;

        var valid = true;
        if (row.length !== 6 && idx !== data.length - 1) {
          currentWarnings.push(`Row ${humanIdx} should have 6 columns`);
          return false;
        }

        if (row.length !== 6 && idx === data.length - 1) {
          return false;
        }

        // do row validations
        if (isHeaderRow(row)) {
          return false;
        }

        // first name
        if (row[0].length === 0) {
          currentWarnings.push(`Row ${humanIdx} First (col 1) is empty`);
          valid = false;
        }

        // last name
        if (row[1].length === 0) {
          currentWarnings.push(`Row ${humanIdx} Last (col 2) is empty`);
          valid = false;
        }

        // phone number
        if (row[2].length > 0 && !phoneNumIsCorrect(row[2])) {
          currentWarnings.push(
            `Row ${humanIdx} has an invalid phone number (col 3)`
          );
          valid = false;
        }

        // email
        function isValidEmail(email) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email);
        }
        if (row[3].length > 0 && !isValidEmail(row[3])) {
          currentWarnings.push(`Row ${humanIdx} has an invalid email (col 4)`);
          valid = false;
        }

        // worker id
        if (row[4].length === 0) {
          currentWarnings.push(`Row ${humanIdx} Worker ID (col 5) is empty`);
          valid = false;
        }

        // shift id
        if (row[5].length === 0) {
          currentWarnings.push(`Row ${humanIdx} Shift ID (col 6) is empty`);
          valid = false;
        }

        return valid;
      });

      var validCandidates = [];
      for (let idx = 0; idx < candidateValids.length; idx++) {
        const candidateValid = candidateValids[idx];
        if (candidateValid) {
          validCandidates.push(data[idx]);
        }
      }

      setWarnings(currentWarnings);
      setCSVData(validCandidates);
    };

    const handleFileUpload = (e) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        setFile(file);
        processFile(file, readCSV);
      }
    };

    const processFile = (file, callback) => {
      if (file.type === "text/csv") {
        console.log("is csv");
        Papa.parse(file, {
          complete: function (results) {
            console.log(results);
            callback(results.data);
          },
        });
      }
    };

    const clearFile = () => {
      setFile("");
      setCSVData("");
    };

    return (
      <>
        <TooltipCustom
          title={
            <>
              <p className="m-auto">
                Supports .csv files, formatted to have six columns: First, Last,
                Phone #, Email, Worker ID, Shift ID
              </p>
            </>
          }
        >
          <Button
            className="btn-upload d-flex flex-col"
            sx={{
              height: "fit-content",
              alignItems: "flex-start",
              padding: "12px",
              backgroundColor: "var(--color-lightgray) !important",
              boxShadow: "none",
              border: "1px dashed lightgray !important",
            }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
          >
            <p className="section-heading">
              Upload a CSV of candidate information, formatted to five columns:
              First, Last, Phone #, Email, Worker ID, Shift ID
            </p>
            <VisuallyHiddenInput
              type="file"
              onChange={handleFileUpload}
              accept="text/csv"
            />
            <div className="d-flex flex-row">
              <div className="btn-main no-hover">Upload file</div>
              {file && (
                <>
                  <p style={{ fontSize: "12px", margin: "auto 12px" }}>
                    {file.name}
                  </p>

                  <Clear
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      clearFile();
                    }}
                    className="color-danger my-auto"
                    fontSize="24px"
                  ></Clear>
                </>
              )}
            </div>
          </Button>
        </TooltipCustom>

        <Grid item xs={12} style={{ marginTop: "32px" }}>
          {warnings.length > 0 && (
            <>
              <Alert severity="error">
                Issues found with the uploaded CSV:
                <br />
                <ol style={{ marginLeft: 10, padding: 0 }}>
                  {warnings.map((warning, idx) => {
                    return <li>{warning}</li>;
                  })}
                </ol>
              </Alert>
              <br />
            </>
          )}
          <Box display="flex" justifyContent="flex-end">
            <Button
              className="btn-main"
              style={{
                fontSize: "14px",
                padding: "10px 15px",
              }}
              onClick={() => {
                setCreateBulkCandidateSessionActiveStep((prev) => prev + 1);
                // handleCreateBulkCandidateSessions();
              }}
              disabled={CSVData.length == 0}
            >
              {CSVData.length == 0
                ? "Next step"
                : `Found ${CSVData.length} valid candidate${
                    CSVData.length > 1 ? "s" : ""
                  }`}{" "}
              <ArrowForward
                style={{ fontSize: "14px", marginLeft: "8px" }}
              />
            </Button>
          </Box>
        </Grid>
      </>
    );
  };
  const inviteBulk = () => {
    const handleCreateBulkCandidateSessions = (data) => {
      /*
        interview_config_id = request.json.get('interviewConfigId')
        user_id = request.json.get('userId')
        unique_session_id = str(uuid.uuid4())
        candidate_first_name = request.json.get('candidateFirstName')
        candidate_last_name = request.json.get('candidateLastName')
        candidate_email = request.json.get('candidateEmail')
        candidate_phone = request.json.get('candidatePhone')
        call_type = request.json.get('callType')
        session_specific_questions = request.json.get('sessionSpecificQuestions', [])
      */
      setBulkCreating(true);
      let candidates = [];
      for (const row of data) {
        candidates.push({
          first_name: row[0],
          last_name: row[1],
          phone: row[2],
          email: row[3],
          worker_id: row[4],
          shift_id: row[5],
          call_type: "web", // TODO: update hardcode, likely add default setting and show visual indication
        });
      }
      var userId = user.email;
      const overrideUserLocal = localStorage.getItem("globalOverrideUser");
      if (overrideUserLocal != null) {
        userId = overrideUserLocal;
      }
      const teamId = localStorage.getItem("teamId");
      const payload = {
        interviewConfigId: interview._id,
        userId: userId,
        candidates: candidates,
        teamId: teamId,
        inviteType: bulkInviteType,
      };
      api
        .createBulkInterviewSessions(payload)
        .then((response) => {
          const result = response.data;
          let successes = result.filter(
            (candidate) => candidate.success
          ).length;
          setShowBulkCandidateLinkCreation(false);
          setGetCompletedSessionsCalled(false);
          setBulkCreating(false);
          setCSVData([]);
          setCreateBulkCandidateSessionActiveStep(0);

          setSnackbarMsg(
            `Successfully created sessions for ${successes} candidate${
              successes > 1 ? "s" : ""
            }.`
          );
          setOpenSnackbar(true);
        })
        .catch((error) => {
          console.log(error);
          setGetCompletedSessionsCalled(false);

          setSnackbarMsg(`Something went wrong creating bulk sessions.`);
          setOpenSnackbar(true);
          setBulkCreating(false);
        });
    };

    return (
      <>
        <h4>{`Found ${CSVData.length} candidate${
          CSVData.length > 1 ? "s" : ""
        }`}</h4>
        <TableContainer style={{ marginTop: 0 }}>
          <Table style={{ marginTop: 0 }}>
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    lineHeight: "20px",
                    backgroundColor: "#FAFAFA",
                    color: "var(--color-heading)",
                    padding: "12px 16px",
                  },
                }}
              >
                <TableCell>First</TableCell>
                <TableCell>Last</TableCell>
                <TableCell>Phone #</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Worker ID</TableCell>
                <TableCell>Shift ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {CSVData.length > 0 &&
                CSVData.map((row) => {
                  return (
                    <TableRow>
                      <TableCell>{row[0]}</TableCell>
                      <TableCell>{row[1]}</TableCell>
                      <TableCell>{row[2]}</TableCell>
                      <TableCell>{row[3]}</TableCell>
                      <TableCell>{row[4]}</TableCell>
                      <TableCell>{row[5]}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid item xs={12} style={{ marginTop: "24px" }}>
          <p style={{ fontSize: "14px", fontFamily: "PPNeueMontreal" }}>
            {" "}
            How do you want to send the invite?
          </p>
        </Grid>
        <div className="d-flex flex-row">
          <Grid item xs={6} style={{ paddingTop: 0, marginRight: "16px" }}>
            <RadioBtn
              value={bulkInviteType}
              setValue={setBulkInviteType}
              expectedValue={"session_link"}
              label={"Session link"}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <RadioBtn
              value={bulkInviteType}
              setValue={setBulkInviteType}
              expectedValue={"email"}
              label={"Send invitation via Email"}
            />
          </Grid>
        </div>
        <Grid item xs={12} style={{ marginTop: "32px" }}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              className="btn-main gray outlined"
              style={{
                fontSize: "14px",
                padding: "10px 15px",
                marginRight: "8px",
              }}
              onClick={() => {
                setCreateBulkCandidateSessionActiveStep((prev) => prev - 1);
              }}
            >
              Go back
            </Button>
            <Button
              className="btn-main"
              style={{
                fontSize: "14px",
                padding: "10px 15px",
              }}
              onClick={() => {
                handleCreateBulkCandidateSessions(CSVData);
              }}
              disabled={
                CSVData.length == 0 || bulkInviteType == "" || bulkCreating
              }
            >
              Create Sessions{" "}
              <ArrowForward
                style={{ fontSize: "14px", marginLeft: "8px" }}
              />
            </Button>
          </Box>
        </Grid>
      </>
    );
  };

  const candidateLinkResults = () => {
    return (
      <Grid container spacing={2}>
        {createUniqueInterviewSessionFailed && (
          <Grid item xs={12}>
            <p style={{ fontSize: 14 }}>
              Candidate session could not be created.
              <br />
              <br />
              Please try again or send us an email:{" "}
              <a href="mailto:shreyas@techinterviewer.ai">
                shreyas@techinterviewer.ai
              </a>
              . We will get back to you with a fix in less than 10 minutes.
            </p>
            <Button
              className="btn-main"
              onClick={() => {
                handleCreateCandidateSession();
              }}
            >
              Try again
            </Button>
          </Grid>
        )}
        {!createUniqueInterviewSessionFailed && (
          <>
            {inviteType === "session_link" && (
              <>
                {generatedCandidateSessionLink.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <p
                        style={{
                          fontWeight: 600,
                          fontSize: 20,
                          fontFamily: "PPNeueMontreal",
                        }}
                      >
                        Candidate Session Link
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <p style={{ fontSize: 14 }}>
                        Share this session link with the candidate:
                      </p>
                      <p
                        style={{
                          color: "gray",
                          fontSize: 12,
                          border: "1px solid lightgray",
                          borderRadius: 10,
                          padding: 10,
                        }}
                      >
                        {generatedCandidateSessionLink}
                      </p>
                    </Grid>
                  </>
                )}
                {generatedCandidateSessionLink.length === 0 && (
                  <Grid item xs={12}>
                    <p style={{ fontSize: "14px" }}>
                      Generating candidate session link...
                    </p>
                    <br />
                    <br />
                    <LinearProgress />
                  </Grid>
                )}
              </>
            )}
            {inviteType === "email" && (
              <>
                {candidateEmailSent && (
                  <Grid item xs={12}>
                    <p style={{ fontSize: "14px" }}>
                      Candidate email has been sent!
                      <br />
                      <br />
                      Feel free to let the candidate know to check their inbox
                      for an invite link.
                    </p>
                  </Grid>
                )}
                {!candidateEmailSent && (
                  <Grid item xs={12}>
                    <p style={{ fontSize: "14px" }}>
                      Sending session invite to candidate's email...
                    </p>
                    <br />
                    <br />
                    <LinearProgress />
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    );
  };

  const renderActiveStepComponent = () => {
    switch (createCandidateSessionActiveStep) {
      case 0:
        return sessionDetails();
      case 1:
        return sessionSpecificQuestions();
      case 2:
        return candidateLinkResults();
      default:
        return null;
    }
  };

  const renderActiveBulkStepComponent = () => {
    switch (createBulkCandidateSessionActiveStep) {
      case 0:
        return uploadBulkCSV();
      case 1:
        return inviteBulk();
      default:
        return null;
    }
  };

  const onCreateUniqueSession = () => {
    setSending(true);
    var userId = user.email;
    const overrideUserLocal = localStorage.getItem("globalOverrideUser");
    if (overrideUserLocal != null) {
      userId = overrideUserLocal;
    }
    createUniqueInterviewSession(
      interview._id,
      createSessionInputs.firstName,
      createSessionInputs.lastName,
      createSessionInputs.email,
      createSessionInputs.phone,
      createSessionInputs.callType,
      userId,
      generatedSessionQuestions
    )
      .then((sessionId) => {
        setUniqueSessionLink(
          `https://beta.techinterviewer.ai?session=${sessionId}`
        );
        setUniqueSessionId(sessionId);
        setSending(false);
      })
      .catch((err) => {
        console.error(err);
        setSending(false);
      });
  };

  const handleCreateCandidateLink = () => {
    setShowCandidateLinkCreation(true);
  };

  const handleBulkCandidateLink = () => {
    setShowBulkCandidateLinkCreation(true);
  };

  const handleExport = () => {
    if (completedSessions.length === 0) {
      setOpenSnackbar(true);
      setSnackbarMsg("No sessions to export");
      return;
    }

    // Define the headers for the CSV
    // First, gather all unique signals from completedSessions
    const allSignals = Array.from(
      new Set(
        completedSessions.flatMap((session) =>
          session.scorecard ? session.scorecard.map((item) => item.signal) : []
        )
      )
    );

    // Gather all unique structured output keys
    const allStructuredOutputKeys = Array.from(
      new Set(
        completedSessions.flatMap((session) =>
          session.metadata &&
          session.metadata.interview_config &&
          (session.structured_outputs ||
            session.metadata.interview_config.found_structured_outputs)
            ? (
                session.structured_outputs ||
                session.metadata.interview_config.found_structured_outputs
              ).flatMap((output) => Object.keys(output))
            : []
        )
      )
    );

    // Define the headers for the CSV
    const headers = [
      "Worker ID",
      "Shift ID",
      "Candidate Name",
      "Status",
      "Score",
      "Date Invited",
      "Date Completed",
      "Duration (minutes)",
      "Email",
      "Phone",
      "Web Link",
      "Phone Link",
      "Admin Link",
      "Overall",
      ...allSignals,
      ...allStructuredOutputKeys,
      "Recording",
      "Summary",
      "Questions Asked",
      "Questions Total",
      "Suspicious Activity",
      "Voicemail Detected",
    ];

    // Map the sessions to CSV rows
    const csvData = completedSessions.map((session) => {
      const scorecardMap = session.scorecard
        ? Object.fromEntries(
            session.scorecard.map((item) => [item.signal, item.score])
          )
        : {};

      const overallAverage = session.scorecard
        ? (
            session.scorecard.reduce((sum, item) => sum + item.score, 0) /
            session.scorecard.length
          ).toFixed(1)
        : "N/A";

      const structuredOutputs =
        session.metadata &&
        session.metadata.interview_config &&
        (session.structured_outputs ||
          session.metadata.interview_config.found_structured_outputs)
          ? (
              session.structured_outputs ||
              session.metadata.interview_config.found_structured_outputs
            ).reduce((acc, output) => ({ ...acc, ...output }), {})
          : {};

      return [
        session.candidate_metadata.worker_id,
        session.candidate_metadata.shift_id,
        `${session.candidate_metadata.first_name} ${session.candidate_metadata.last_name}`,
        SessionStatusString[session.status],
        session.scorecard && session.scorecard.length > 0
          ? passSession(session)
            ? "Accept"
            : "Reject"
          : "N/A",
        `"${new Date(session.created_at.$date).toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        })}"`,
        `"${
          session.date_completed_unix_timestamp
            ? new Date(
                parseInt(session.date_completed_unix_timestamp)
              ).toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              })
            : ""
        }"`,
        `"${
          session.messages && session.messages.length > 0
            ? (
                (session.messages[session.messages.length - 1].createdAt -
                  session.messages[0].createdAt) /
                60
              ).toFixed(2)
            : ""
        }"`,
        session.candidate_metadata.email,
        session.candidate_metadata.phone,
        `https://beta.techinterviewer.ai?session=${session.unique_session_id}`,
        session.client_type === "phone" || session.client_type === "mobile"
          ? `https://beta.techinterviewer.ai/phone?session=${session.unique_session_id}`
          : "",
        `https://admin.techinterviewer.ai/interviews/${interview._id}/sessions/${session._id.$oid}`,
        overallAverage,
        ...allSignals.map((signal) => scorecardMap[signal] || "N/A"),
        ...allStructuredOutputKeys.map((key) => {
          const value = structuredOutputs[key];
          if (typeof value === "string") {
            return `"${value.replace(/"/g, '""')}"`;
          } else if (value !== undefined && value !== null) {
            return `"${String(value).replace(/"/g, '""')}"`;
          } else {
            return "N/A";
          }
        }),
        session.status === SessionStatus.COMPLETED
          ? `"${session.recording_url}"` || ""
          : "",
        session.status === SessionStatus.COMPLETED
          ? `"${session.summary ? session.summary.replace(/"/g, '""') : ""}"`
          : "",
        session.status === SessionStatus.COMPLETED &&
        session.metadata &&
        session.metadata.interview_config &&
        session.metadata.interview_config.questions
          ? session.parrot_text_index + 1
          : "N/A",
        session.status === SessionStatus.COMPLETED &&
        session.metadata &&
        session.metadata.interview_config &&
        session.metadata.interview_config.questions
          ? session.metadata.interview_config.questions.length
          : "N/A",
        session.potentialCheating ? "Yes" : "No",
        session.voicemail_detected ? "Yes" : "No",
      ];
    });

    // Prepend headers to csvData
    csvData.unshift(headers);

    // Convert to CSV string
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element and trigger download
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `interview_sessions_${interview._id}_${new Date().toISOString()}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    setOpenSnackbar(true);
    setSnackbarMsg("Export completed successfully");
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const viewStructuredOutputs = (session) => {
    console.log(
      "viewStructuredOutputs",
      session.metadata.interview_config.found_structured_outputs
    );
    setCurrentStructuredOutputs(
      session.structured_outputs ||
        session.metadata.interview_config.found_structured_outputs
    );
    setShowStructuredOutputs(true);
  };

  const viewScorecard = (session) => {
    console.log("viewScorecard", session);
    if (session.scorecards) {
      setCurrentScorecards(session.scorecards);
    } else {
      setCurrentScorecard(session.scorecard);
    }
    setShowScorecard(true);
  };

  const viewTranscript = (session) => {
    console.log("viewTranscript", session);
    setCurrentMessages(session.messages);
    setShowMessages(true);
  };

  const viewSummary = (session) => {
    setCurrentSummary(session.summary);
    setShowSummary(true);
  };

  const viewVideoRecording = (session) => {
    setCurrentVideoRecording(session.videoRecording);
    setShowVideoRecording(true);
  };

  const onUpdatePrompt = () => {
    var promptVal = newPrompt.length > 0 ? newPrompt : "    ";
    updatePrompt(interview._id, promptVal)
      .then((resp) => {
        setInterview(resp);
        setOpenSnackbar(true);
        setSnackbarMsg("Interview Prompt changes saved");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onUpdateEvalPrompt = () => {
    var promptVal = newEvalPrompt.length > 0 ? newEvalPrompt : "    ";
    updateEvalPrompt(interview._id, promptVal)
      .then((resp) => {
        setInterview(resp);
        setOpenSnackbar(true);
        setSnackbarMsg("Eval Prompt changes saved");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDragEnd = (event) => {
    console.log("handleDragEnd");
    const { active, over } = event;

    if (active.id !== over.id) {
      let updatedInterview = interview;

      const oldIndex = interview.questions.findIndex(
        (question) => question.id === active.id
      );
      const newIndex = interview.questions.findIndex(
        (question) => question.id === over.id
      );
      updatedInterview = {
        ...interview,
        questions: arrayMove(interview.questions, oldIndex, newIndex),
      };

      axios
        .post(`${proxyEndpoint}/interviews/${interview._id}`, {
          interview: updatedInterview,
        })
        .then((response) => {
          setInterview(response.data);
          setCurrentQuestion({
            text: "",
            numFollowUps: 1,
            // section: '',
            editIndex: -1,
          });
          setOpenSnackbar(true);
          setSnackbarMsg("Questions have been updated");
        })
        .catch((error) => {
          console.error("Failed to update interview questions:", error);
        });
    }
  };

  const handleDragEndSessionSpecific = (event) => {
    console.log("handleDragEndSessionSpecific");
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = generatedSessionQuestions.questions.findIndex(
        (question) => question.id === active.id
      );
      const newIndex = generatedSessionQuestions.questions.findIndex(
        (question) => question.id === over.id
      );
      const updatedGeneratedQuestions = {
        ...generatedSessionQuestions,
        questions: arrayMove(
          generatedSessionQuestions.questions,
          oldIndex,
          newIndex
        ),
      };
      setGenerateSessionQuestions(updatedGeneratedQuestions);
    }
  };

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  function groupBy(arr, property) {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...(acc[cur[property]] || []), cur];
      return acc;
    }, {});
  }

  function refreshSections(interview) {
    const foundSections = interview.questions
      .reduce((acc, question) => {
        if (!acc.includes(question.section)) acc.push(question.section);
        return acc;
      }, [])
      .sort(sectionSort);
    setSections(foundSections);
  }

  function sectionSort(a, b) {
    const top_val = "Introduction";
    const null_val = "undefined";

    // top_val comes first
    if (a == top_val) return -1;
    if (b == top_val) return 1;

    // nulls sort after anything else
    if (a === null_val) {
      return 1;
    }
    if (b === null_val) {
      return -1;
    }

    // neither matches `value` - compare them normally
    return a.localeCompare(b);
  }

  const handleDeleteSession = (session) => {
    var userId = user.email;
    const overrideUserLocal = localStorage.getItem("globalOverrideUser");
    if (overrideUserLocal != null) {
      userId = overrideUserLocal;
    }
    const teamId = localStorage.getItem("teamId");
    const sessionId = session._id.$oid;

    api
      .deleteSession({ userId, teamId, sessionId })
      .then((response) => {
        if (response.data.error) {
          console.error(`Failed to delete session: ${response.data.error}`);
          return;
        } else {
          // refresh completed sessions
          setGetCompletedSessionsCalled(false);

          setSnackbarMsg(response.data.message);
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        console.error("Failed to delete session:", error);
      });
  };

  const renderQuestions = () => {
    const question_sections = groupBy(interview.questions, "section");
    // show sections only if at least 2 different sections specified
    const showSections = Object.keys(question_sections).length > 1;

    return (
      <div>
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          {errMsgs.length > 0 &&
            errMsgs.map((errMsg) => {
              return (
                <Alert
                  severity="error"
                  style={{ width: "80%", margin: "auto", marginBottom: "15px" }}
                >
                  {errMsg}
                </Alert>
              );
            })}
          <>
            {/* TODO: style rest of interview tabs to match consistent action buttons, modals */}
            <div
              className="d-flex flex-row"
              style={{
                padding: "12px 16px",
                borderBottom: "1px solid lightgray",
                marginBottom: 0,
              }}
            >
              <div
                style={{
                  backgroundColor: "var(--color-lightgray)",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "600",
                  padding: "6px 8px",
                }}
              >
                All Questions ({interview.questions.length})
              </div>

              <button
                className="btn-main outlined gray"
                style={{
                  marginLeft: "auto",
                  marginBottom: 0,
                  padding: "6px 9.24px 6px 8px",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
                disabled={getInterviewCalled == false}
                onClick={() => {
                  setCurrentPreviewQuestion(interview.questions[0]);
                  setSelectedPreview(interview.questions[0]);
                  setPreviewOpen(true);
                }}
              >
                Simulate interview
              </button>
              <button
                className="btn-main"
                style={{
                  marginLeft: "12px",
                  marginBottom: 0,
                  padding: "6px 9.24px 6px 8px",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
                onClick={() => {
                  setShowAddQuestionInput(true);
                }}
              >
                + Add Question
              </button>
            </div>
            <Grid container style={{ marginTop: 0 }}>
              <TableContainer style={{ marginTop: 0 }}>
                <Table style={{ marginTop: 0 }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          lineHeight: "20px",
                          backgroundColor: "#FAFAFA",
                          color: "var(--color-heading)",
                          padding: "12px 16px",
                        },
                      }}
                    >
                      <TableCell colSpan={2} style={{ width: "60%" }}>
                        Question
                      </TableCell>
                      <TableCell style={{ minWidth: "72px" }}>
                        Follow Ups
                      </TableCell>
                      <TableCell>Quick Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(question_sections)
                      .sort(sectionSort)
                      .map((keyName, keyIndex) => (
                        <SortableContext
                          items={question_sections[keyName].map(
                            (question, idx) => {
                              return question.id;
                            }
                          )}
                          strategy={verticalListSortingStrategy}
                        >
                          {showSections && keyName != "undefined" && (
                            <TableSection label={keyName}></TableSection>
                          )}
                          {question_sections[keyName].map((question, idx) => (
                            <>
                              <InterviewDetailDraggableRow
                                idx={idx}
                                question={question}
                                questions={interview.questions}
                                setCurrentQuestion={setCurrentQuestion}
                                setQuestionDeletionId={setQuestionDeletionId}
                                setQuestionDeletionIndex={
                                  setQuestionDeletionIndex
                                } // TODO: Adjust question delete to base on id not index
                                setShowQuestionDeletionConfirmation={
                                  setShowQuestionDeletionConfirmation
                                }
                                setShowAddQuestionInput={
                                  setShowAddQuestionInput
                                }
                              />
                            </>
                          ))}
                        </SortableContext>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
          {/* Add Question */}
          <Dialog open={showAddQuestionInput}>
            <DialogTitle>
              {showAddQuestionInput
                ? currentQuestion.editIndex > -1
                  ? "Edit Question"
                  : "Add Question"
                : "Questions"}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12} style={{ height: "220px" }}>
                  <FormField
                    label="Enter Your Question"
                    multiline
                    minRows={8}
                    placeholder="What has been the highlight of your past role?"
                    value={currentQuestion.text}
                    onChange={(e) => {
                      setCurrentQuestion({
                        ...currentQuestion,
                        text: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label="Number of Follow Up Questions "
                    type="number"
                    tooltip
                    tooltipTitle={
                      <>
                        <p className="m-auto">
                          Wayfaster assessments are fully dynamic, meaning
                          follow up questions are generated based on what the
                          candidate says.
                          <span style={{ color: "#20C58A" }}>
                            {" "}
                            Watch a small demo here to see how it works
                          </span>
                          .
                        </p>
                      </>
                    }
                    value={currentQuestion.numFollowUps}
                    onChange={(e) => {
                      setCurrentQuestion({
                        ...currentQuestion,
                        numFollowUps: e.target.value,
                      });
                    }}
                  />
                  {/* <p style={{ fontSize: 14, marginLeft: 10, marginTop: 20, fontWeight: '500px' }}>What are follow up questions? Wayfaster assessments are fully dynamic, meaning questions are generated based on what the candidate says. <span style={{ color: '#20C58A'}}>Watch a small demo here to see how it works.</span></p> */}
                </Grid>

                {/*<Grid item xs={12}>
                    <FormField
                      label="Add to a Section"
                      select
                      // defaultValue="Background"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Assign the question to a section of the interview (i.e. Introduction).
                        </p>
                      </>}
                      value={currentQuestion.section}
                      onChange={(e) => {setCurrentQuestion({ ...currentQuestion, section: e.target.value })}}
                      >
                        {sections.map((section) =>
                        <MenuItem key={section} value={section}>
                          {section}
                        </MenuItem>)}
                        <div className="d-flex flex-row">
                          <TextField
                          placeholder="Add a section..."
                          variant="standard"
                          fullWidth={true}
                          InputProps={{
                            disableUnderline: true,
                            style: { padding: '8px 16px', marginBottom: '0px',
                            border: 'none', borderRadius: '6px', fontSize: '16px' },
                            autocomplete: "off",
                            autoFocus: "off"
                          }}
                          sx={{
                            ['& .MuiInput-input'] : {
                              padding: 0,
                            }
                          }}
                          onKeyDown={(e) => e.stopPropagation()}
                          value={newSectionName}
                          onChange={(e) => {
                            setNewSectionName(e.target.value);
                          }}
                        />
                        {newSectionName.trim() != '' && !sections.includes(newSectionName) && <button className="btn-main small d-flex gray outlined my-auto"
                          style={{ margin: 'auto 16px' }}
                          onClick={() => {
                            setSections((prev) => [...prev, newSectionName]);
                            setNewSectionName("");
                          }}>
                          <Add fontSize="14px" className="color-heading m-auto" />
                        </button>}
                      </div>
                      </FormField>
                  </Grid>*/}
              </Grid>
            </DialogContent>
            {/* Error Messages */}
            <div className="d-flex" style={{ width: "100%", marginTop: "-16px" }}>
              {errMsgs.length > 0 && (
                <Alert
                  key={"alert"}
                  severity="error"
                  style={{ width: "100%", margin: "8px auto" }}
                >
                  {errMsgs.map((errMsg) => {
                    return <p>{errMsg}</p>;
                  })}
                </Alert>
              )}
            </div>
            <DialogActions>
              <div style={{ display: "flex", padding: "16px" }}>
                <Button
                  className="btn gray outlined"
                  fullWidth={false}
                  style={{ flex: 1, marginRight: "12px", maxWidth: "62px" }}
                  onClick={() => {
                    setShowAddQuestionInput(false);
                    setCurrentQuestion({
                      text: "",
                      numFollowUps: 1,
                      // section: '',
                      editIndex: -1,
                    });
                  }}
                >
                  Cancel
                </Button>
                <IconButton
                  className="btn black"
                  style={{ flex: 1, width: "140px" }}
                  onClick={() => {
                    const questionText = currentQuestion.text;
                    const words = questionText.split(" ");
                    let msgs = [];
                    if (words.length < 5) {
                      msgs.push("Question must have at least 5 words.");
                    }

                    const numFollowUps = parseInt(currentQuestion.numFollowUps);
                    if (isNaN(numFollowUps) || numFollowUps < 0) {
                      msgs.push(
                        "Number of follow up questions must be at least 0."
                      );
                    }

                    if (msgs.length > 0) {
                      setErrMsgs(msgs);
                    } else {
                      setErrMsgs([]);
                      if (currentQuestion.editIndex >= 0) {
                        let updatedQuestions = [...interview.questions];
                        updatedQuestions[currentQuestion.editIndex] = {
                          id: interview.questions[currentQuestion.editIndex][
                            "id"
                          ],
                          question: currentQuestion.text,
                          follow_ups: currentQuestion.numFollowUps,
                          // section: currentQuestion.section
                        };
                        let updatedInterview = { ...interview };
                        updatedInterview.questions = updatedQuestions;

                        axios
                          .post(
                            `${proxyEndpoint}/interviews/${interview._id}`,
                            {
                              interview: updatedInterview,
                            }
                          )
                          .then((response) => {
                            if (response.data.message) {
                              setOpenSnackbar(true);
                              setSnackbarMsg(response.data.message);
                            } else {
                              setInterview(response.data);
                              // refreshSections(response.data);
                              setCurrentQuestion({
                                text: "",
                                numFollowUps: 1,
                                // section: '',
                                editIndex: -1,
                              });

                              setOpenSnackbar(true);
                              setSnackbarMsg("Question updated successfully");
                            }
                          })
                          .catch((error) => {
                            console.error(
                              "Failed to update interview questions:",
                              error
                            );
                          });
                      } else {
                        let updatedInterview = { ...interview };
                        const newQuestionId = uuidv4();
                        updatedInterview.questions = [
                          ...updatedInterview.questions,
                          {
                            question: currentQuestion.text,
                            follow_ups: currentQuestion.numFollowUps,
                            // section: currentQuestion.section,
                            id: newQuestionId,
                          },
                        ];

                        axios
                          .post(
                            `${proxyEndpoint}/interviews/${interview._id}`,
                            {
                              interview: updatedInterview,
                            }
                          )
                          .then((response) => {
                            if (response.data.message) {
                              setOpenSnackbar(true);
                              setSnackbarMsg(response.data.message);
                            } else {
                              setInterview(response.data);
                              // refreshSections(response.data);
                              setCurrentQuestion({
                                text: "",
                                numFollowUps: 1,
                                // section: '',
                                editIndex: -1,
                              });

                              setOpenSnackbar(true);
                              setSnackbarMsg("Question added successfully");
                            }
                          })
                          .catch((error) => {
                            console.error(
                              "Failed to update interview questions:",
                              error
                            );
                          });
                      }
                      setShowAddQuestionInput(false);
                    }
                  }}
                >
                  Save
                  <ArrowForward
                    style={{ marginLeft: "8px", fontSize: "16px" }}
                  />
                </IconButton>
              </div>
            </DialogActions>
          </Dialog>
          {/* QUESTION DELETION */}
          <Dialog
            open={showQuestionDeletionConfirmation}
            onClose={() => setShowQuestionDeletionConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Question Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this question? This action
                cannot be undone:
                {questionDeletionId != "" && (
                  <p
                    style={{
                      fontSize: 16,
                      padding: "25px",
                      border: "0.5px solid darkgray",
                      borderRadius: "10px",
                    }}
                  >
                    <b>Question:</b>{" "}
                    {interview.questions.find(
                      (question) => question.id == questionDeletionId
                    ) && (
                      <>
                        {
                          interview.questions.find(
                            (question) => question.id == questionDeletionId
                          ).question
                        }
                      </>
                    )}
                    <br />
                    <br />
                    <b>Follow Ups:</b>{" "}
                    {interview.questions.find(
                      (question) => question.id == questionDeletionId
                    ) && (
                      <>
                        {
                          interview.questions.find(
                            (question) => question.id == questionDeletionId
                          ).follow_ups
                        }
                      </>
                    )}
                  </p>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="btn outlined"
                onClick={() => setShowQuestionDeletionConfirmation(false)}
                color="primary"
              >
                Close
              </Button>
              <Button
                className="btn outlined danger"
                onClick={() => {
                  // Assuming there's a function to handle the deletion
                  const tempInterview = { ...interview };
                  let delete_idx = tempInterview.questions.findIndex(
                    (question) => question.id == questionDeletionId
                  );
                  tempInterview.questions.splice(delete_idx, 1);
                  fetch(`${proxyEndpoint}/interviews/${interview_id}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      interview: tempInterview,
                    }),
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      setInterview(data);
                      refreshSections(data);
                      setSnackbarMsg("Question has been deleted!");
                      setOpenSnackbar(true);
                      console.log("Question deleted successfully");
                    })
                    .catch((error) => {
                      console.error("Error deleting question:", error);
                    });
                  setQuestionDeletionId("");
                  setQuestionDeletionIndex(-1);
                  setShowQuestionDeletionConfirmation(false);
                }}
                color="primary"
                autoFocus
              >
                Delete!
              </Button>
            </DialogActions>
          </Dialog>
        </DndContext>
      </div>
    );
  };

  const renderScorecard = () => {
    const SCORE_SCALES = [2, 3, 4, 5, 6];

    const saveAddScoring = () => {
      let msgs = [];

      // VALIDATION LOGIC
      if (currentSignal.signal == "") {
        msgs.push("Skill name must be filled in.");
      }
      if (currentSignal.weight <= 0) {
        msgs.push("Weight must be greater than 0%");
      }
      if (currentSignal.weight >= 100) {
        msgs.push("Weight must not be greater than 100%");
      }

      if (msgs.length > 0) {
        setErrMsgs(msgs);
      } else {
        setErrMsgs([]);

        // get criteria
        let criteria = {};
        for (let i = 0; i < Number(currentSignal.scale); i++) {
          criteria[`${i + 1}PointCriteria`] =
            currentSignal[`${i + 1}PointCriteria`];
        }

        let updatedInterview = { ...interview };
        if (currentSignal.editIndex >= 0) {
          let updatedSignals = [...interview.scorecard];
          updatedSignals[currentSignal.editIndex] = {
            signal: currentSignal.signal,
            scale: currentSignal.scale,
            ...criteria,
            weight: currentSignal.weight,
          };
          updatedInterview = { ...updatedInterview, scorecard: updatedSignals };
        } else {
          updatedInterview = {
            ...updatedInterview,
            scorecard: [
              ...interview.scorecard,
              {
                signal: currentSignal.signal,
                scale: currentSignal.scale,
                ...criteria,
                weight: currentSignal.weight,
              },
            ],
          };
        }

        axios
          .post(`${proxyEndpoint}/interviews/${interview._id}`, {
            interview: updatedInterview,
          })
          .then((response) => {
            let message = "Scorecard changes saved successfully";
            console.log(response);
            if (response.data.message)
              message = response.data.message; // no changes to interview
            else setInterview(response.data);

            cancelShowAddModal("signal");
            cancelShowAddModal("signal-assist");

            setCurrentSignal({
              signal: "",
              weight: 0,
              scale: 4,
              "4PointCriteria": "",
              "3PointCriteria": "",
              "2PointCriteria": "",
              "1PointCriteria": "",
              editIndex: -1,
            });

            setSnackbarMsg(message);
            setOpenSnackbar(true);
          })
          .catch((error) => {
            console.error("Failed to update interview scorecard:", error);
          });
      }
    };

    // returns a normalized criteria set based on all generated criteria
    const normalizeCriteria = (generatedCriteria, scale) => {
      switch (scale) {
        case 2:
          // Poor, Excellent
          return {
            "1PointCriteria": generatedCriteria["1PointCriteria"],
            "2PointCriteria": generatedCriteria["6PointCriteria"],
          };

        case 3:
          // Poor, Average, Excellent
          return {
            "1PointCriteria": generatedCriteria["1PointCriteria"],
            "2PointCriteria": generatedCriteria["3PointCriteria"],
            "3PointCriteria": generatedCriteria["6PointCriteria"],
          };

        case 4:
          // Poor, Fair, Good, Excellent
          return {
            "1PointCriteria": generatedCriteria["1PointCriteria"],
            "2PointCriteria": generatedCriteria["2PointCriteria"],
            "3PointCriteria": generatedCriteria["4PointCriteria"],
            "4PointCriteria": generatedCriteria["6PointCriteria"],
          };

        case 5:
          // Poor, Fair, Average, Good, Excellent
          return {
            "1PointCriteria": generatedCriteria["1PointCriteria"],
            "2PointCriteria": generatedCriteria["2PointCriteria"],
            "3PointCriteria": generatedCriteria["3PointCriteria"],
            "4PointCriteria": generatedCriteria["4PointCriteria"],
            "5PointCriteria": generatedCriteria["6PointCriteria"],
          };

        case 6:
          // Poor, Fair, Average, Good, Very Good, Excellent
          return generatedCriteria;

        default:
          // Poor, Fair, Average, Good, Very Good, Excellent
          return generatedCriteria;
      }
    };

    const handleGenerateScoringCriteria = ({ context, scale, signal }) => {
      setScoringGenerating(true);
      api
        .generateScoringCriteria({
          context,
          signal,
          scale,
          title: interview.title,
          job_title: interview.jobTitle,
        })
        .then((response) => {
          let data = response.data;
          let criteria = {};

          for (let i = 0; i < Number(data.scale); i++) {
            let point = data.criteria[`${i + 1}PointCriteria`];
            criteria[`${i + 1}PointCriteria`] = "";
            for (const [key, value] of Object.entries(point)) {
              criteria[`${i + 1}PointCriteria`] += `• ${key}: ${value}\n`;
            }
          }
          setGeneratedCriteria({
            ...criteria,
          });

          setCurrentSignal({
            signal: data.signal,
            weight: 0,
            scale: scale,
            ...normalizeCriteria(criteria, scale),
            description: context,
          });
          setScoringActiveCriteria("1PointCriteria");
          setScoringAssistStep((prev) => prev + 1);
          setScoringGenerating(false);
        })
        .catch((error) => {
          console.log(error);
          setScoringGenerating(false);
        });
    };

    const cancelShowAddModal = (type) => {
      setErrMsgs([]);
      switch (type) {
        case "signal":
          setScoringAssistStep(0);
          setScoringAddTab(0);
          setScoringActiveCriteria("1PointCriteria");
          setCurrentSignal({
            signal: "",
            weight: 0,
            scale: 4,
            "4PointCriteria": "",
            "3PointCriteria": "",
            "2PointCriteria": "",
            "1PointCriteria": "",
            editIndex: -1,
          });
          return setShowAddSignalInput(false);
        case "signal-assist":
          setScoringAssistStep(0);
          setScoringAddTab(0);
          setScoringActiveCriteria("1PointCriteria");
          setCurrentSignal({
            signal: "",
            weight: 0,
            scale: 4,
            "4PointCriteria": "",
            "3PointCriteria": "",
            "2PointCriteria": "",
            "1PointCriteria": "",
            editIndex: -1,
          });
          return setShowAddSignalAssistInput(false);
        default:
          return;
      }
    };

    const getScoringPlaceholder = () => {
      let placeholder = "Criteria for ";
      switch (scoringActiveCriteria) {
        case "6PointCriteria":
          return placeholder + `"${getScoringDescriptor(6)}"` + " score (6)";
        case "5PointCriteria":
          return placeholder + `"${getScoringDescriptor(5)}"` + " score (5)";
        case "4PointCriteria":
          return placeholder + `"${getScoringDescriptor(4)}"` + " score (4)";
        case "3PointCriteria":
          return placeholder + `"${getScoringDescriptor(3)}"` + " score (3)";
        case "2PointCriteria":
          return placeholder + `"${getScoringDescriptor(2)}"` + " score (2)";
        case "1PointCriteria":
          return placeholder + `"${getScoringDescriptor(1)}"` + " score (1)";
        default:
          return null;
      }
    };

    const getScoringDescriptor = (i) => {
      let scale = Number(currentSignal.scale);

      // min / max value base cases
      if (i == 1) return "Poor";
      if (i == scale || i == SCORE_SCALES[SCORE_SCALES.length - 1])
        return "Excellent";

      switch (scale) {
        case 3:
          if (i == 2) return "Fair";
        case 4:
          if (i == 2) return "Fair";
          if (i == 3) return "Good";
        case 5:
          if (i == 2) return "Fair";
          if (i == 3) return "Average";
          if (i == 4) return "Good";
        case 6:
          if (i == 2) return "Fair";
          if (i == 3) return "Average";
          if (i == 4) return "Good";
          if (i == 5) return "Very Good";
      }
    };

    return (
      <div>
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          {errMsgs.length > 0 &&
            errMsgs.map((errMsg) => {
              return (
                <Alert
                  severity="error"
                  style={{ width: "80%", margin: "auto", marginBottom: "15px" }}
                >
                  {errMsg}
                </Alert>
              );
            })}
          <>
            <div
              className="d-flex flex-row"
              style={{
                padding: "12px 16px",
                borderBottom: "1px solid lightgray",
                marginBottom: 0,
              }}
            >
              <div
                style={{
                  backgroundColor: "var(--color-lightgray)",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "600",
                  padding: "6px 8px",
                }}
              >
                Scorecard
              </div>
              <button
                className="btn-main assistant d-flex"
                style={{ marginLeft: "auto" }}
                onClick={() => {
                  setShowAddSignalAssistInput(true);
                }}
              >
                <AutoAwesomeOutlined
                  className="my-auto"
                  fontSize="24px"
                  style={{ marginRight: "6px" }}
                />
                Scoring Assistant
              </button>
              <button
                className="btn-main"
                style={{
                  marginLeft: "12px",
                  marginBottom: 0,
                  padding: "6px 9.24px 6px 8px",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
                onClick={() => {
                  setShowAddSignalInput(true);
                }}
              >
                + Add Skill
              </button>
            </div>
            <Grid container style={{ marginTop: 0 }}>
              <TableContainer style={{ marginTop: 0 }}>
                <Table style={{ marginTop: 0 }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          lineHeight: "20px",
                          backgroundColor: "#FAFAFA",
                          color: "var(--color-heading)",
                          padding: "12px 16px",
                        },
                      }}
                    >
                      <TableCell>Skill</TableCell>
                      <TableCell>Weight (%)</TableCell>
                      <TableCell>Quick Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {interview.scorecard &&
                      interview.scorecard.map((signal, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{signal.signal}</TableCell>
                          <TableCell>{signal.weight}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                // edit
                                setCurrentSignal({
                                  ...interview.scorecard[idx],
                                  editIndex: idx,
                                });
                                setShowAddSignalInput(true);
                              }}
                              className="drop-shadow-btn"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontWeight: 500,
                                marginRight: "8px",
                                borderRadius: "9px",
                                border: "0.5px solid lightgray",
                                padding: "6px 9px 6px 8px",
                              }}
                            >
                              <img src={EditIconNewSvg} alt="Edit" /> Edit
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setSignalDeletionIndex(idx);
                                setShowSignalDeletionConfirmation(true);
                              }}
                              className="drop-shadow-btn"
                              style={{
                                background: "#FFF8F8",
                                color: "#F33D3D",
                                fontSize: "14px",
                                fontWeight: 500,
                                marginRight: "4px",
                                borderRadius: "9px",
                                border: "0.5px solid lightgray",
                                padding: "6px 9px 6px 8px",
                              }}
                            >
                              <img src={DeleteIconNewSvg} alt="Delete" /> Delete
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>

          {/* Add / Edit Signal */}
          <Dialog open={showAddSignalInput} maxWidth={"md"}>
            <DialogContent>
              <h3
                className="section-heading"
                style={{ fontSize: "20px", marginTop: 0 }}
              >
                Add scoring expectations
              </h3>
              <p style={{ fontSize: "16px", color: "gray", margin: 0 }}>
                Determine what the candidate should be graded on in the
                interview.
              </p>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: "24px" }}>
                  <FormField
                    label="What is your candidate signal called?"
                    placeholder="e.g. English proficiency"
                    value={currentSignal.signal}
                    onChange={(e) => {
                      setCurrentSignal({
                        ...currentSignal,
                        signal: e.target.value,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <FormSelect
                    label="Point Scale"
                    tooltip
                    tooltipTitle={
                      <>
                        <p className="m-auto">
                          Determines the scale to grade points over, from a
                          minimum score of 0 points to this maximum value.
                        </p>
                      </>
                    }
                    variant="outlined"
                    value={currentSignal.scale}
                    onChange={(e) => {
                      let scale_num = Number(e.target.value);
                      setCurrentSignal({ ...currentSignal, scale: scale_num });
                      setScoringActiveCriteria("1PointCriteria");
                      setScoringAddTab(0);
                    }}
                  >
                    {SCORE_SCALES.map((score) => {
                      return <MenuItem value={score}>{score}</MenuItem>;
                    })}
                  </FormSelect>
                </Grid>
                <Grid item xs={10}>
                  <h5 className="input-label d-flex">Scores</h5>
                  <Tabs
                    value={scoringAddTab}
                    onChange={handleScoringAddTabChange}
                    style={{
                      background: "white",
                      marginBottom: "16px",
                      marginTop: "8px",
                    }}
                    sx={{
                      ["& .MuiTabs-indicator"]: {
                        backgroundColor: "transparent",
                      },
                      ["& .MuiTab-root"]: {
                        color: "var(--color-main-light)",
                        fontSize: "14px",
                        borderRadius: "8px",
                        padding: "4px 8px",
                        marginRight: "4px",
                        fontFamily: "PPNeueMontreal",
                        textTransform: "none",
                        minHeight: "40px",
                      },
                      ["& .MuiTab-root.Mui-selected"]: {
                        color: "var(--color-main)",
                        backgroundColor: "var(--color-lightgray)",
                        fontWeight: 600,
                      },
                    }}
                  >
                    {[...Array(Number(currentSignal.scale))].map((e, i) => {
                      return (
                        <Tab
                          label={`${i + 1} (${getScoringDescriptor(i + 1)})`}
                          onClick={() =>
                            setScoringActiveCriteria(`${i + 1}PointCriteria`)
                          }
                          disableRipple
                        />
                      );
                    })}
                  </Tabs>
                </Grid>

                <FormField
                  label={getScoringPlaceholder()}
                  tooltip
                  tooltipTitle={
                    <>
                      <p className="m-auto">
                        Write a criteria description that the candidate has to
                        meet to be evaluated for this score.
                      </p>
                    </>
                  }
                  style={{ marginBottom: "16px" }}
                  multiline
                  minRows={8}
                  placeholder={getScoringPlaceholder()}
                  value={currentSignal[scoringActiveCriteria]}
                  onChange={(e) => {
                    setCurrentSignal({
                      ...currentSignal,
                      [scoringActiveCriteria]: e.target.value,
                    });
                  }}
                />

                <FormField
                  label="How much do you weigh this signal? Give a percentage (%) between 1 and 100."
                  value={currentSignal.weight}
                  type="Number"
                  onChange={(e) => {
                    setCurrentSignal({
                      ...currentSignal,
                      weight: Number(e.target.value),
                    });
                  }}
                />
              </Grid>
            </DialogContent>
            {/* Error Messages */}
            <div className="d-flex" style={{ width: "100%" }}>
              {errMsgs.length > 0 &&
                errMsgs.map((errMsg) => {
                  return (
                    <Alert
                      severity="error"
                      style={{ width: "100%", margin: "auto" }}
                    >
                      {errMsg}
                    </Alert>
                  );
                })}
            </div>
            <DialogActions>
              <div
                className="d-flex flex-row"
                style={{ width: "100%", padding: "16px" }}
              >
                <Button
                  className="btn-main gray outlined"
                  style={{ marginLeft: "auto" }}
                  onClick={() => cancelShowAddModal("signal")}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-main"
                  style={{ marginLeft: "12px" }}
                  onClick={() => saveAddScoring()}
                >
                  Save
                </Button>
              </div>
            </DialogActions>
          </Dialog>

          {/* Add Signal Assist */}
          <Dialog open={showAddSignalAssistInput} maxWidth={"md"}>
            <DialogContent>
              <h3
                className="section-heading"
                style={{ fontSize: "20px", marginTop: 0 }}
              >
                {scoringAssistStep === 0
                  ? "Generate score outputs with scoring assistant"
                  : "Generated expectations from candidate"}
              </h3>
              <p style={{ fontSize: "16px", color: "gray", margin: 0 }}>
                Determine what the candidate should be graded on in the
                interview.
              </p>
              {scoringAssistStep === 0 && (
                <Grid container>
                  <Grid item xs={12} style={{ marginTop: "24px" }}>
                    <FormField
                      label="What is your candidate signal called?"
                      placeholder="e.g. English proficiency"
                      value={currentSignal.signal}
                      onChange={(e) => {
                        setCurrentSignal({
                          ...currentSignal,
                          signal: e.target.value,
                        });
                      }}
                    />
                    <FormField
                      label="Describe what is most important to you in this signal."
                      multiline
                      minRows={8}
                      placeholder="e.g. It is very important to me that the candidate is fluent in English, has good sentence construction and understands what I say to him/her"
                      value={currentSignal.description}
                      onChange={(e) => {
                        setCurrentSignal({
                          ...currentSignal,
                          description: e.target.value,
                        });
                      }}
                    />
                    <FormSelect
                      label="Point Scale"
                      tooltip
                      tooltipTitle={
                        <>
                          <p className="m-auto">
                            Determines the scale to grade points over, from a
                            minimum score of 0 points to this maximum value.
                          </p>
                        </>
                      }
                      variant="outlined"
                      value={currentSignal.scale}
                      onChange={(e) => {
                        let scale_num = Number(e.target.value);
                        setCurrentSignal({
                          ...currentSignal,
                          scale: scale_num,
                        });
                        setScoringActiveCriteria("1PointCriteria");
                        setScoringAddTab(0);
                      }}
                    >
                      {SCORE_SCALES.map((score) => {
                        return <MenuItem value={score}>{score}</MenuItem>;
                      })}
                    </FormSelect>
                  </Grid>
                </Grid>
              )}

              {scoringAssistStep === 1 && (
                <Grid container>
                  <Grid item xs={12} style={{ marginTop: "24px" }}>
                    <FormField
                      label="What is your candidate signal called?"
                      placeholder="e.g. English proficiency"
                      value={currentSignal.signal}
                      onChange={(e) => {
                        setCurrentSignal({
                          ...currentSignal,
                          signal: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <FormSelect
                      label="Point Scale"
                      tooltip
                      tooltipTitle={
                        <>
                          <p className="m-auto">
                            Determines the scale to grade points over, from a
                            minimum score of 0 points to this maximum value.
                          </p>
                        </>
                      }
                      variant="outlined"
                      value={currentSignal.scale}
                      onChange={(e) => {
                        let scale_num = Number(e.target.value);
                        setCurrentSignal({
                          signal: currentSignal.signal,
                          weight: currentSignal.weight,
                          scale: scale_num,
                          ...normalizeCriteria(generatedCriteria, scale_num),
                          description: currentSignal.description,
                        });
                        setScoringActiveCriteria("1PointCriteria");
                        setScoringAddTab(0);
                      }}
                    >
                      {SCORE_SCALES.map((score) => {
                        return <MenuItem value={score}>{score}</MenuItem>;
                      })}
                    </FormSelect>
                  </Grid>
                  <Grid item xs={10}>
                    <h5 className="input-label d-flex">Scores</h5>
                    <Tabs
                      value={scoringAddTab}
                      onChange={handleScoringAddTabChange}
                      style={{
                        background: "white",
                        marginBottom: "16px",
                        marginTop: "8px",
                      }}
                      sx={{
                        ["& .MuiTabs-indicator"]: {
                          backgroundColor: "transparent",
                        },
                        ["& .MuiTab-root"]: {
                          color: "var(--color-main-light)",
                          fontSize: "14px",
                          borderRadius: "8px",
                          padding: "4px 8px",
                          marginRight: "4px",
                          fontFamily: "PPNeueMontreal",
                          textTransform: "none",
                          minHeight: "40px",
                        },
                        ["& .MuiTab-root.Mui-selected"]: {
                          color: "var(--color-main)",
                          backgroundColor: "var(--color-lightgray)",
                          fontWeight: 600,
                        },
                      }}
                    >
                      {[...Array(Number(currentSignal.scale))].map((e, i) => {
                        return (
                          <Tab
                            label={`${i + 1} (${getScoringDescriptor(i + 1)})`}
                            onClick={() =>
                              setScoringActiveCriteria(`${i + 1}PointCriteria`)
                            }
                            disableRipple
                          />
                        );
                      })}
                    </Tabs>
                  </Grid>

                  <FormField
                    label={getScoringPlaceholder()}
                    tooltip
                    tooltipTitle={
                      <>
                        <p className="m-auto">
                          Write a criteria description that the candidate has to
                          meet to be evaluated for this score.
                        </p>
                      </>
                    }
                    style={{ marginBottom: "16px" }}
                    multiline
                    minRows={8}
                    placeholder={getScoringPlaceholder()}
                    value={currentSignal[scoringActiveCriteria]}
                    onChange={(e) => {
                      setCurrentSignal({
                        ...currentSignal,
                        [scoringActiveCriteria]: e.target.value,
                      });
                    }}
                  />

                  <FormField
                    label="How much do you weigh this signal? Give a percentage (%) between 1 and 100."
                    value={currentSignal.weight}
                    type="Number"
                    onChange={(e) => {
                      setCurrentSignal({
                        ...currentSignal,
                        weight: Number(e.target.value),
                      });
                    }}
                  />
                </Grid>
              )}

              {/* Error Messages */}
              <div className="d-flex" style={{ width: "100%" }}>
                {errMsgs.length > 0 &&
                  errMsgs.map((errMsg) => {
                    return (
                      <Alert
                        severity="error"
                        style={{ width: "100%", margin: "auto" }}
                      >
                        {errMsg}
                      </Alert>
                    );
                  })}
              </div>
            </DialogContent>
            <DialogActions>
              <div
                className="d-flex flex-row"
                style={{ width: "100%", padding: "16px" }}
              >
                <Button
                  className="btn-main gray outlined"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    cancelShowAddModal("signal-assist");
                    setErrMsgs([]);
                  }}
                >
                  Cancel
                </Button>
                {scoringAssistStep > 0 && (
                  <Button
                    className="btn-main gray outlined"
                    style={{ marginLeft: "12px" }}
                    onClick={() => {
                      setScoringAssistStep((prev) => prev - 1);
                      setErrMsgs([]);
                    }}
                  >
                    Back
                  </Button>
                )}
                {scoringAssistStep === 0 && (
                  <Button
                    className="btn-main assistant"
                    disabled={
                      scoringGenerating ||
                      currentSignal.signal == "" ||
                      currentSignal.description == ""
                    }
                    style={{ marginLeft: "12px" }}
                    onClick={() =>
                      handleGenerateScoringCriteria({
                        signal: currentSignal.signal,
                        scale: currentSignal.scale,
                        context: currentSignal.description,
                      })
                    }
                  >
                    {scoringGenerating ? (
                      <>
                        Generating... &nbsp;&nbsp;
                        <CircularProgress
                          className="m-auto"
                          size={14}
                          style={{ color: "white", marginLeft: "8px" }}
                        />
                      </>
                    ) : (
                      <>
                        <AutoAwesomeOutlined
                          className="my-auto"
                          fontSize="24px"
                          style={{ marginRight: "6px" }}
                        />
                        Generate
                      </>
                    )}
                  </Button>
                )}

                {scoringAssistStep === 1 && (
                  <Button
                    className="btn-main"
                    style={{ marginLeft: "12px" }}
                    onClick={() => {
                      saveAddScoring();
                    }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </DialogActions>
          </Dialog>
          <Dialog
            open={showSignalDeletionConfirmation}
            onClose={() => setShowSignalDeletionConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Skill Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this skill? This action cannot
                be undone:
                {signalDeletionIndex > -1 && (
                  <p
                    style={{
                      fontSize: 16,
                      padding: "25px",
                      border: "0.5px solid darkgray",
                      borderRadius: "10px",
                    }}
                  >
                    <b>Skill:</b>{" "}
                    {interview.scorecard[signalDeletionIndex].signal}
                    <br />
                    <br />
                    <b>Weight:</b>{" "}
                    {interview.scorecard[signalDeletionIndex].weight}%
                  </p>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="btn outlined"
                onClick={() => setShowSignalDeletionConfirmation(false)}
                color="primary"
              >
                Close
              </Button>
              <Button
                className="btn outlined danger"
                onClick={() => {
                  const tempInterview = { ...interview };
                  tempInterview.scorecard.splice(signalDeletionIndex, 1);
                  fetch(`${proxyEndpoint}/interviews/${interview._id}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      interview: tempInterview,
                    }),
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      setInterview(data);
                      setSnackbarMsg("Skill has been deleted!");
                      setOpenSnackbar(true);
                      console.log("Skill deleted successfully");
                    })
                    .catch((error) => {
                      console.error("Error deleting skill:", error);
                    });
                  setSignalDeletionIndex(-1);
                  setShowSignalDeletionConfirmation(false);
                }}
                color="primary"
                autoFocus
              >
                Delete!
              </Button>
            </DialogActions>
          </Dialog>
        </DndContext>
      </div>
    );
  };

  const handleLogoUpload = (e) => {
    console.log(e);
  } 

  const clearFile = () => {
    console.log("clear file");
  }

  
  const renderInboundOutbound = () => {
    return (
      <div style={{ position: 'relative' }}>
        {errMsgs.length > 0 &&
          errMsgs.map((errMsg) => {
            return (
              <Alert
                severity="error"
                style={{ width: "80%", margin: "auto", marginBottom: "15px" }}
              >
                {errMsg}
              </Alert>
            );
          })}
        <>
          <div
            style={{
              display: "flex",
              gap: "8px",
              padding: "12px 16px",
              borderBottom: "1px solid lightgray",
              marginBottom: 0,
            }}
          >
            {completedSessions.length > 0 && <Button
              onClick={() => {
                setInboundOutbound("inbound");
              }}
              style={{
                backgroundColor:
                  inboundOutbound === "inbound"
                    ? "var(--color-lightgray)"
                    : "#fff",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "600",
                padding: "4px 8px",
                textTransform: "none",
                color: "var(--color-main)",
              }}
            >
              Inbound
            </Button>}

            <Button
              onClick={() => {
                setInboundOutbound("outbound");
              }}
              style={{
                backgroundColor:
                  inboundOutbound === "outbound"
                    ? "var(--color-lightgray)"
                    : "#fff",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "600",
                padding: "4px 8px",
                textTransform: "none",
                color: "var(--color-main)",
              }}
            >
              Outbound
            </Button>
          </div>
          {inboundOutbound === "inbound" && (
            <Grid container style={{ marginTop: 0 }}>
              
              {completedSessions.length > 0 && (
                          <>
                            <TableContainer style={{ overflowX: "auto" }}>
                              <Table>
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      "& .MuiTableCell-root": {
                                        lineHeight: "20px",
                                        backgroundColor: "#FAFAFA",
                                        color: "var(--color-heading)",
                                        padding: "12px 16px",
                                      },
                                    }}
                                  >
                                    {ANALYSIS_ACCESS.includes(user.email) && (
                                      <TableCell
                                        sx={{
                                          width: "42px",
                                        }}
                                      >
                                        <Checkbox
                                          style={{
                                            marginLeft: 0,
                                            paddingLeft: 0,
                                          }}
                                          checked={
                                            selectedSessions.length ===
                                            filteredSessions.filter(
                                              (session) =>
                                                session.scorecard &&
                                                Object.keys(session.scorecard)
                                                  .length > 0
                                            ).length
                                          }
                                          onClick={() => {
                                            if (
                                              selectedSessions.length <
                                              filteredSessions.length
                                            ) {
                                              const validSessions =
                                                filteredSessions.filter(
                                                  (session) =>
                                                    session.scorecard &&
                                                    Object.keys(
                                                      session.scorecard
                                                    ).length > 0
                                                );
                                              setSelectedSessions(
                                                validSessions.map(
                                                  (session) => session._id.$oid
                                                )
                                              );
                                            } else {
                                              setSelectedSessions([]);
                                            }
                                          }}
                                        />
                                      </TableCell>
                                    )}
                                    <TableCell>Candidate Name</TableCell>
                                    <TableCell>
                                      <TableSortLabel
                                        active={true}
                                        direction={statusOrder}
                                        onClick={() => handleSortStatus()}
                                      >
                                        Status
                                      </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Score</TableCell>
                                    <TableCell>
                                      <TableSortLabel
                                        active={true}
                                        direction={invitedOrder}
                                        onClick={() => handleSortInvited()}
                                      >
                                        Invited
                                      </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                      <TableSortLabel
                                        active={true}
                                        direction={completedOrder}
                                        onClick={() =>
                                          handleSortCompleted(
                                            filteredSessions,
                                            completedOrder === "asc"
                                              ? "desc"
                                              : "asc"
                                          )
                                        }
                                      >
                                        Completed
                                      </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Quick Actions</TableCell>
                                    {ANALYSIS_ACCESS.includes(user.email) ? (
                                      <TableCell>Re-run Analysis</TableCell>
                                    ) : (
                                      <TableCell></TableCell>
                                    )}
                                    {ANALYSIS_ACCESS.includes(user.email) && (
                                      <TableCell>
                                        Call Candidate's Phone
                                      </TableCell>
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {filteredSessions.map((session, idx) => {
                                    return (
                                      <TableRow
                                        className={`clickableRow ${
                                          selectedSessions.includes(
                                            session._id.$oid
                                          )
                                            ? `clickableRowSelected`
                                            : ""
                                        }`}
                                        key={idx}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          window.open(
                                            `/interviews/${interview._id}/sessions/${session._id.$oid}`,
                                            "_blank"
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {ANALYSIS_ACCESS.includes(
                                          user.email
                                        ) && (
                                          <TableCell
                                            sx={{
                                              width: "50px",
                                            }}
                                          >
                                            <Checkbox
                                              style={{
                                                marginLeft: 0,
                                                paddingLeft: 0,
                                              }}
                                              checked={selectedSessions.includes(
                                                session._id.$oid
                                              )}
                                              disabled={
                                                !session.scorecard ||
                                                Object.keys(session.scorecard)
                                                  .length === 0
                                              }
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (
                                                  session.scorecard &&
                                                  Object.keys(session.scorecard)
                                                    .length > 0
                                                ) {
                                                  toggleSessionSelected(
                                                    session._id.$oid
                                                  );
                                                }
                                              }}
                                            />
                                          </TableCell>
                                        )}
                                        <TableCell>
                                          {session.candidate_metadata && (
                                            <>
                                              {session.candidate_metadata
                                                .first_name +
                                                " " +
                                                session.candidate_metadata
                                                  .last_name}
                                            </>
                                          )}
                                          {!session.candidate_metadata && (
                                            <>N/A</>
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {session.voicemail_detected ? (
                                            <ChipTag
                                              variant="default"
                                              text="Voicemail"
                                            />
                                          ) : (
                                            <>
                                              {session.status ===
                                                SessionStatus.NOT_STARTED && (
                                                <ChipTag
                                                  variant="not-started"
                                                  text="Not Started"
                                                />
                                              )}
                                              {session.status ===
                                                SessionStatus.INCOMPLETE && (
                                                <ChipTag
                                                  variant="in-progress"
                                                  text="Incomplete"
                                                />
                                              )}
                                              {session.status ===
                                                SessionStatus.IN_PROGRESS && (
                                                <ChipTag
                                                  variant="in-progress"
                                                  text="In Progress"
                                                />
                                              )}
                                              {session.status ===
                                                SessionStatus.COMPLETED && (
                                                <ChipTag
                                                  variant="completed"
                                                  text="Completed"
                                                />
                                              )}
                                            </>
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {!session.voicemail_detected &&
                                          session.status ===
                                            SessionStatus.COMPLETED ? (
                                            !session.scorecard ||
                                            session.scorecard.length === 0 ? (
                                              <ChipTag
                                                variant="pending"
                                                text="N/A"
                                              />
                                            ) : session.customerOverrideCandidateAction ===
                                                "accept" ||
                                              passSession(session) ? (
                                              <ChipTag
                                                variant="accept"
                                                text="Accept"
                                              />
                                            ) : (
                                              <ChipTag
                                                variant="reject"
                                                text="Reject"
                                              />
                                            )
                                          ) : (
                                            <ChipTag
                                              variant="pending"
                                              text="Pending"
                                            />
                                          )}
                                          {session.potentialCheating && (
                                            <ChipTag
                                              variant="danger"
                                              text="Suspicious Activity"
                                              style={{
                                                marginLeft: "8px",
                                                backgroundColor: "#FF4D4D",
                                                color: "white",
                                              }}
                                            />
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {session.created_at.$date
                                            ? new Date(
                                                session.created_at.$date
                                              ).toLocaleString()
                                            : new Date(
                                                session.created_at * 1000
                                              ).toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                          {session.date_completed_unix_timestamp
                                            ? new Date(
                                                session.date_completed_unix_timestamp
                                              ).toLocaleString()
                                            : "N/A"}
                                        </TableCell>
                                        <TableCell>
                                          <button
                                            className="btn-icon my-auto"
                                            ref={moreActionsRef.current}
                                            style={{ margin: "auto 12px" }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleListShareToggle(e, idx);
                                            }}
                                          >
                                            <MoreVert
                                              className="color-heading m-auto"
                                              style={{
                                                fontSize: "20px",
                                              }}
                                            />
                                          </button>

                                          {/* More Actions */}
                                          <Popper
                                            open={actionsOpenIdx == idx}
                                            anchorEl={quickActionsAnchorEl}
                                            role={undefined}
                                            placement="bottom-end"
                                            transition
                                          >
                                            {({
                                              TransitionProps,
                                              placement,
                                            }) => (
                                              <Grow
                                                {...TransitionProps}
                                                style={{
                                                  transformOrigin: "right top",
                                                }}
                                              >
                                                <Paper>
                                                  <ClickAwayListener
                                                    onClickAway={
                                                      handleListActionsClose
                                                    }
                                                  >
                                                    <MenuList
                                                      autoFocusItem={
                                                        actionsOpenIdx == idx
                                                      }
                                                      id="composition-menu"
                                                      aria-labelledby="composition-button"
                                                      sx={{
                                                        ["& .MuiMenuItem-root"]:
                                                          {
                                                            fontSize: "14px",
                                                          },
                                                        ["& .MuiTypography-root"]:
                                                          {
                                                            fontSize: "14px",
                                                          },
                                                      }}
                                                      onKeyDown={
                                                        handleListKeyDown
                                                      }
                                                    >
                                                      <MenuItem
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          navigator.clipboard
                                                            .writeText(buildUniqueSessionLink(session.unique_session_id, 'web'))
                                                            .then((val) => {
                                                              setSnackbarMsg(
                                                                "Web link copied to clipboard successfully"
                                                              );
                                                              setOpenSnackbar(
                                                                true
                                                              );
                                                              setCopied(true);
                                                              handleListActionsClose();
                                                            })
                                                            .catch((err) => {
                                                              console.error(
                                                                err
                                                              );
                                                            });
                                                        }}
                                                      >
                                                        <ListItemIcon>
                                                          <Share fontSize="14px" />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                          Copy Web Link
                                                        </ListItemText>
                                                      </MenuItem>
                                                      {session.client_type &&
                                                        (session.client_type ===
                                                          "phone" ||
                                                          session.client_type ===
                                                            "mobile") && (
                                                          <MenuItem
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              navigator.clipboard
                                                                .writeText(buildUniqueSessionLink(session.unique_session_id, 'phone'))
                                                                .then((val) => {
                                                                  setSnackbarMsg(
                                                                    "Phone link copied to clipboard successfully"
                                                                  );
                                                                  setOpenSnackbar(
                                                                    true
                                                                  );
                                                                  setCopied(
                                                                    true
                                                                  );
                                                                  handleListActionsClose();
                                                                })
                                                                .catch(
                                                                  (err) => {
                                                                    console.error(
                                                                      err
                                                                    );
                                                                  }
                                                                );
                                                            }}
                                                          >
                                                            <ListItemIcon>
                                                              <Phone fontSize="14px" />
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                              Copy Phone Link
                                                            </ListItemText>
                                                          </MenuItem>
                                                        )}
                                                      <MenuItem
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          setSessionToBeDeleted(
                                                            session
                                                          );
                                                          setShowSessionDeletionConfirmation(
                                                            true
                                                          );

                                                          // close more actions menu
                                                          handleListActionsClose();
                                                        }}
                                                      >
                                                        <ListItemIcon>
                                                          <Delete fontSize="14px" />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                          Delete Session
                                                        </ListItemText>
                                                      </MenuItem>
                                                    </MenuList>
                                                  </ClickAwayListener>
                                                </Paper>
                                              </Grow>
                                            )}
                                          </Popper>
                                        </TableCell>
                                        {ANALYSIS_ACCESS.includes(user.email) &&
                                          session.call_id && (
                                            <TableCell>
                                              <button
                                                className="btn-main"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  rerunAnalysis(session, idx);
                                                }}
                                                disabled={
                                                  rerunningIdxes.includes(
                                                    idx
                                                  ) ||
                                                  rerunningIds.includes(
                                                    session._id.$oid
                                                  ) ||
                                                  session.running_summary_analysis ||
                                                  session.running_structured_outputs_analysis ||
                                                  session.running_scoring_analysis
                                                }
                                              >
                                                {rerunningIdxes.includes(idx) ||
                                                rerunningIds.includes(
                                                  session._id.$oid
                                                ) ||
                                                session.running_summary_analysis ||
                                                session.running_structured_outputs_analysis ||
                                                session.running_scoring_analysis
                                                  ? "Running..."
                                                  : "Re-run analysis"}
                                              </button>
                                            </TableCell>
                                          )}
                                        {(!ANALYSIS_ACCESS.includes(
                                          user.email
                                        ) ||
                                          !session.call_id) && (
                                          <TableCell></TableCell>
                                        )}
                                        <TableCell>
                                          {session.candidate_metadata &&
                                            session.candidate_metadata.phone &&
                                            /^\+\d{11}$/.test(
                                              session.candidate_metadata.phone
                                            ) && (
                                              <button
                                                className="btn-main"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  setSnackbarMsg(
                                                    "Calling their phone now, please wait a moment..."
                                                  );
                                                  setOpenSnackbar(true);

                                                  fetch(
                                                    `https://retell-main-proxy-69440b772c45.herokuapp.com/register-phone-call`,
                                                    {
                                                      method: "POST",
                                                      headers: {
                                                        "Content-Type":
                                                          "application/json",
                                                      },
                                                      body: JSON.stringify({
                                                        sessionId:
                                                          session.unique_session_id,
                                                      }),
                                                    }
                                                  )
                                                    .then((response) =>
                                                      response.json()
                                                    )
                                                    .then((data) => {
                                                      setSnackbarMsg(
                                                        "Called phone successfully, they will be notified shortly."
                                                      );
                                                      setOpenSnackbar(true);
                                                    })
                                                    .catch((error) => {
                                                      console.error(
                                                        "Error starting phone session:",
                                                        error
                                                      );
                                                      setSnackbarMsg(
                                                        "Error calling their phone, please try again."
                                                      );
                                                      setOpenSnackbar(true);
                                                    });
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Phone
                                                    sx={{
                                                      fontSize: 16,
                                                      marginRight: "4px",
                                                    }}
                                                  />
                                                  <span>Call</span>
                                                </div>
                                              </button>
                                            )}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            {/* SESSION DELETION */}
                            <Dialog
                              open={showSessionDeletionConfirmation}
                              onClose={() =>
                                setShowSessionDeletionConfirmation(false)
                              }
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {"Confirm Session Deletion"}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure you want to delete this session?
                                  You and the candidate will not be able to
                                  access this session anymore.
                                  {sessionToBeDeleted && (
                                    <p
                                      style={{
                                        fontSize: 16,
                                        padding: "24px",
                                        border: "0.5px solid darkgray",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <b>Candidate Name:</b>{" "}
                                      {
                                        sessionToBeDeleted.candidate_metadata
                                          .first_name
                                      }{" "}
                                      {
                                        sessionToBeDeleted.candidate_metadata
                                          .last_name
                                      }
                                      <br />
                                      <br />
                                      <b style={{ marginRight: 8 }}>Status:</b>
                                      {sessionToBeDeleted.voicemail_detected ? (
                                        <ChipTag
                                          variant="default"
                                          text="Voicemail"
                                        />
                                      ) : (
                                        <>
                                          {sessionToBeDeleted.status ===
                                            SessionStatus.NOT_STARTED && (
                                            <ChipTag
                                              variant="not-started"
                                              text="Not Started"
                                            />
                                          )}
                                          {sessionToBeDeleted.status ===
                                            SessionStatus.INCOMPLETE && (
                                            <ChipTag
                                              variant="in-progress"
                                              text="Incomplete"
                                            />
                                          )}
                                          {sessionToBeDeleted.status ===
                                            SessionStatus.IN_PROGRESS && (
                                            <ChipTag
                                              variant="in-progress"
                                              text="In Progress"
                                            />
                                          )}
                                          {sessionToBeDeleted.status ===
                                            SessionStatus.COMPLETED && (
                                            <ChipTag
                                              variant="completed"
                                              text="Completed"
                                            />
                                          )}
                                        </>
                                      )}
                                      <br />
                                      <br />
                                      <b style={{ marginRight: 8 }}>Score:</b>
                                      {!sessionToBeDeleted.voicemail_detected &&
                                      sessionToBeDeleted.status ===
                                        SessionStatus.COMPLETED ? (
                                        !sessionToBeDeleted.scorecard ||
                                        sessionToBeDeleted.scorecard.length ===
                                          0 ? (
                                          <ChipTag
                                            variant="pending"
                                            text="N/A"
                                          />
                                        ) : sessionToBeDeleted.customerOverrideCandidateAction ===
                                            "accept" ||
                                          passSession(sessionToBeDeleted) ? (
                                          <ChipTag
                                            variant="accept"
                                            text="Accept"
                                          />
                                        ) : (
                                          <ChipTag
                                            variant="reject"
                                            text="Reject"
                                          />
                                        )
                                      ) : (
                                        <ChipTag
                                          variant="pending"
                                          text="Pending"
                                        />
                                      )}
                                      {sessionToBeDeleted.potentialCheating && (
                                        <ChipTag
                                          variant="danger"
                                          text="Suspicious Activity"
                                          style={{
                                            marginLeft: "8px",
                                            backgroundColor: "#FF4D4D",
                                            color: "white",
                                          }}
                                        />
                                      )}
                                    </p>
                                  )}
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  className="btn outlined"
                                  onClick={() =>
                                    setShowSessionDeletionConfirmation(false)
                                  }
                                  color="primary"
                                >
                                  Close
                                </Button>
                                <Button
                                  className="btn outlined danger"
                                  onClick={() => {
                                    handleDeleteSession(sessionToBeDeleted);
                                    setShowSessionDeletionConfirmation(false);
                                  }}
                                  color="primary"
                                  autoFocus
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )}
            </Grid>
          )}
          {inboundOutbound === "outbound" && (
            <>
            <div style={{ position: 'relative', zIndex: '10' }}>
              <Box className="d-flex flex-col" style={{ height: "500px" }}>
                {interview.phone_number ? (
                  <div
                    className="d-flex flex-col m-auto"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <p
                      className="text-center font-inter color-gray"
                      style={{ fontSize: "14px", lineHeight: "24px", marginBottom: '24px' }}
                    >
                      Copy and paste the snippet below before your website's
                      closing <code style={{ color: 'var(--color-action)' }}>&lt;/head&gt;</code> tag.
                    </p>
                    <iframe
                      className="mx-auto iframe-cta"
                      src={`${window.location.origin}/call-to-apply/${interview._id}`}
                      width="450px"
                      height="250px"
                      title="Call to Apply"
                    ></iframe>

                    <div
                      className="d-flex flex-row mx-auto"
                      style={{ marginTop: "36px" }}
                    >
                      <button
                        className="btn-main gray outlined d-flex"
                        style={{
                          width: "fit-content",
                          height: "36px",
                          marginRight: "12px",
                        }}
                        onClick={() => {
                          setShowGeneratePhoneNumber(true);
                        }}
                      >
                        <img
                          className="my-auto"
                          style={{ fontSize: "14px", marginRight: "8px" }}
                          src={EditIconNewSvg}
                          alt="Edit"
                        />
                        <span className="font-inter my-auto">Edit widget</span>
                      </button>
                      <button
                        className="btn-main action d-flex"
                        style={{ width: "fit-content", height: "36px" }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `<iframe src="${window.location.origin}/call-to-apply/${interview._id}" width="450px" height="250px" title="Call to Apply"></iframe>`
                          );
                          setSnackbarMsg("Copied embed code to clipboard!");
                          setOpenSnackbar(true);
                        }}
                      >
                        <ContentCopyOutlined
                          className="my-auto"
                          sx={{
                            fontSize: "14px",
                            color: "var(--color-lightgray)",
                            marginRight: "8px",
                          }}
                        ></ContentCopyOutlined>
                        <span className="font-inter my-auto">Copy embed code</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="d-flex flex-col m-auto"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <p
                      className="text-center font-inter color-gray"
                      style={{ fontSize: "14px", lineHeight: "24px" }}
                    >
                      Trusted by 1000s of high-volume recruiters
                    </p>
                    <h3
                      className="text-center font-inter"
                      style={{ fontSize: "20px", lineHeight: "28px" }}
                    >
                      Allow candidates to call in 24/7 for an interview. <br />
                      Embed on your board
                    </h3>
                    <p
                      className="text-center font-inter color-gray"
                      style={{ fontSize: "14px", lineHeight: "24px" }}
                    >
                      Get more candidates to call to apply for your job, anytime.
                    </p>

                    <button
                      className="btn-main mx-auto"
                      style={{
                        width: "fit-content",
                        height: "44px",
                        marginTop: "24px",
                      }}
                      disabled={generatingPhoneNumber}
                      onClick={() => {
                        setShowGeneratePhoneNumber(true);
                      }}
                    >
                      <span className="font-inter" style={{ fontSize: "14px" }}>
                        {generatingPhoneNumber
                          ? "Generating..."
                          : "Generate Phone Number"}
                      </span>
                    </button>
                  </div>
                )}
              </Box>
            </div>
            <img src={CallToApplyBg} alt="Call to Apply" style={{ margin: 'auto', position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', zIndex: '1' }}/>
            </>
          )}

          {/* Generate phone number */}
          <Dialog maxWidth="md" fullWidth={true} open={showGeneratePhoneNumber}>
            <DialogTitle>Generate Phone Number</DialogTitle>
            <DialogContent>
              <FormField
                label="Headline"
                placeholder="Take a recruitment interview now"
                required
                value={outbound.headline}
                onChange={(e) => {
                  setOutbound({
                    ...outbound,
                    headline: e.target.value,
                  });
                }}
              />
              <FormField
                label="Message"
                placeholder="Want to interview for a job at this company now? Call this number"
                required
                value={outbound.message}
                onChange={(e) => {
                  setOutbound({
                    ...outbound,
                    message: e.target.value,
                  });
                }}
              />

              <h5 className="input-label d-flex"></h5>
              <TooltipCustom
                title={
                  <>
                    <p className="m-auto">Supports .png .svg .jpg files.</p>
                  </>
                }
              >
                <h5
                  className="input-label d-flex"
                  style={{ marginLeft: "0", fontSize: "14px" }}
                >
                  Branding elements
                </h5>
                <Button
                  className="btn-upload d-flex flex-col m-auto"
                  sx={{
                    height: "fit-content",
                    alignItems: "flex-start",
                    padding: "24px",
                    backgroundColor: "var(--color-lightgray) !important",
                    boxShadow: "none",
                    border: "1px dashed lightgray !important",
                  }}
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                >
                  <p className="font-inter section-heading color-gray">
                    Upload company logo (.png .svg .jpg formats allowed)
                  </p>
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleLogoUpload}
                    accept="image/*"
                  />
                  <div className="d-flex flex-row d-flex mx-auto">
                    <div className="btn-main no-hover">Upload file</div>
                    {file && (
                      <>
                        <p style={{ fontSize: "12px", margin: "auto 12px" }}>
                          {file.name}
                        </p>

                        <Clear
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            clearFile();
                          }}
                          className="color-danger my-auto"
                          fontSize="24px"
                        ></Clear>
                      </>
                    )}
                  </div>
                </Button>
              </TooltipCustom>
            </DialogContent>
            <DialogActions>
              <div style={{ display: "flex", padding: "16px" }}>
                <Button
                  className="btn-main gray outlined"
                  fullWidth={false}
                  style={{ flex: 1, marginRight: "12px", maxWidth: "62px" }}
                  onClick={() => {
                    setShowGeneratePhoneNumber(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-main"
                  disabled={!outbound.headline || !outbound.message}
                  style={{ flex: 1, width: "fit-content" }}
                  onClick={() => {
                    setGeneratingPhoneNumber(true);
                    axios
                      .post(
                        proxyEndpoint +
                          "/interviews/" +
                          interview._id +
                          "/generate-phone-number",
                        {
                          outbound: {
                            headline: outbound.headline,
                            message: outbound.message,
                          },
                        }
                      )
                      .then((response) => {
                        setInterview({
                          ...interview,
                          phone_number: response.data.phone_number,
                        });
                        setSnackbarMsg("Phone number has been generated!");
                        setOpenSnackbar(true);
                      })
                      .finally(() => {
                        setGeneratingPhoneNumber(false);
                        setShowGeneratePhoneNumber(false);
                      });
                  }}
                >
                  <span className="font-inter" style={{ fontSize: "14px" }}>
                    {generatingPhoneNumber
                      ? interview.phone_number
                        ? "Updating..."
                        : "Generating..."
                      : interview.phone_number
                      ? "Update"
                      : "Generate Phone Number"}
                  </span>
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </>
      </div>
    );
  };

  const renderOutputs = () => {
    return (
      <div>
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          {errMsgs.length > 0 &&
            errMsgs.map((errMsg) => {
              return (
                <Alert
                  severity="error"
                  style={{ width: "80%", margin: "auto", marginBottom: "15px" }}
                >
                  {errMsg}
                </Alert>
              );
            })}
          <>
            <div
              className="d-flex flex-row"
              style={{
                padding: "12px 16px",
                borderBottom: "1px solid lightgray",
                marginBottom: 0,
              }}
            >
              <div
                style={{
                  backgroundColor: "var(--color-lightgray)",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "600",
                  padding: "6px 8px",
                }}
              >
                Structured Outputs ({interview.structuredOutputs.length})
              </div>
              <button
                className="btn-main"
                style={{
                  marginLeft: "auto",
                  marginBottom: 0,
                  padding: "6px 9.24px 6px 8px",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
                onClick={() => {
                  setShowAddOutputInput(true);
                }}
              >
                + Add Output
              </button>
            </div>
            <Grid container style={{ marginTop: 0 }}>
              <TableContainer style={{ marginTop: 0 }}>
                <Table style={{ marginTop: 0 }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          lineHeight: "20px",
                          backgroundColor: "#FAFAFA",
                          color: "var(--color-heading)",
                          padding: "12px 16px",
                        },
                      }}
                    >
                      <TableCell>Key</TableCell>
                      <TableCell>Output Description</TableCell>
                      <TableCell style={{ minWidth: "56px" }}>
                        Data Type
                      </TableCell>
                      <TableCell>Quick Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {interview.structuredOutputs &&
                      interview.structuredOutputs.map((output, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{output.key}</TableCell>
                          <TableCell>
                            {output.extraction_instructions}
                          </TableCell>
                          <TableCell>{output.data_type}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setCurrentOutput({
                                  ...interview.structuredOutputs[idx],
                                  editIndex: idx,
                                });
                                setShowAddOutputInput(true);
                              }}
                              className="drop-shadow-btn"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontWeight: 500,
                                marginRight: "8px",
                                borderRadius: "9px",
                                border: "0.5px solid lightgray",
                                padding: "6px 9px 6px 8px",
                              }}
                            >
                              <img src={EditIconNewSvg} alt="Edit" /> Edit
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOutputDeletionIndex(idx);
                                setShowOutputDeletionConfirmation(true);
                              }}
                              className="drop-shadow-btn"
                              style={{
                                background: "#FFF8F8",
                                color: "#F33D3D",
                                fontSize: "14px",
                                fontWeight: 500,
                                marginRight: "4px",
                                borderRadius: "9px",
                                border: "0.5px solid lightgray",
                                padding: "6px 9px 6px 8px",
                              }}
                            >
                              <img src={DeleteIconNewSvg} alt="Delete" /> Delete
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
          {/* Add/Edit Output */}
          <Dialog open={showAddOutputInput}>
            <DialogTitle>
              {currentOutput.editIndex > -1 ? "Edit Output" : "Add Output"}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: "25px" }}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    label="Key"
                    placeholder="past_employers"
                    value={currentOutput.key}
                    onChange={(e) => {
                      setCurrentOutput({
                        ...currentOutput,
                        key: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "25px" }}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    label="Extraction Instructions"
                    placeholder="Get a list of companies the person has worked at"
                    value={currentOutput.extraction_instructions}
                    onChange={(e) => {
                      setCurrentOutput({
                        ...currentOutput,
                        extraction_instructions: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "25px" }}>
                  <Select
                    label="Data Type"
                    variant="outlined"
                    fullWidth={true}
                    value={currentOutput.data_type}
                    onChange={(e) => {
                      setCurrentOutput({
                        ...currentOutput,
                        data_type: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="List">List</MenuItem>
                    <MenuItem value="Text">Text</MenuItem>
                    <MenuItem value="Number">Number</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </DialogContent>
            {/* Error Messages */}
            <div
              className="d-flex"
              style={{ width: "100%", marginTop: "-16px" }}
            >
              {errMsgs.length > 0 &&
                errMsgs.map((errMsg) => {
                  return (
                    <Alert
                      severity="error"
                      style={{ width: "100%", margin: "auto" }}
                    >
                      {errMsg}
                    </Alert>
                  );
                })}
            </div>
            <DialogActions>
              <div style={{ display: "flex", padding: "16px" }}>
                <Button
                  className="btn gray outlined"
                  fullWidth={false}
                  style={{ flex: 1, marginRight: "12px", maxWidth: "62px" }}
                  onClick={() => {
                    setShowAddOutputInput(false);
                    setCurrentOutput({
                      key: "",
                      data_type: "List",
                      extraction_instructions: "",
                      editIndex: -1,
                    });
                  }}
                >
                  Cancel
                </Button>
                <IconButton
                  className="btn black"
                  style={{ flex: 1, width: "140px" }}
                  onClick={() => {
                    let msgs = [];
                    if (
                      !currentOutput.extraction_instructions ||
                      !currentOutput.data_type ||
                      !currentOutput.key
                    ) {
                      msgs.push("All fields must be filled in");
                    }

                    if (msgs.length > 0) {
                      setErrMsgs(msgs);
                    } else {
                      setErrMsgs([]);
                      let updatedInterview = { ...interview };
                      if (currentOutput.editIndex >= 0) {
                        let updatedOutputs = [...interview.structuredOutputs];
                        updatedOutputs[currentOutput.editIndex] = {
                          key: currentOutput.key,
                          data_type: currentOutput.data_type,
                          extraction_instructions:
                            currentOutput.extraction_instructions,
                        };
                        updatedInterview = {
                          ...updatedInterview,
                          structuredOutputs: updatedOutputs,
                        };
                      } else {
                        updatedInterview = {
                          ...updatedInterview,
                          structuredOutputs: [
                            ...interview.structuredOutputs,
                            {
                              key: currentOutput.key,
                              data_type: currentOutput.data_type,
                              extraction_instructions:
                                currentOutput.extraction_instructions,
                            },
                          ],
                        };
                      }

                      axios
                        .post(`${proxyEndpoint}/interviews/${interview._id}`, {
                          interview: updatedInterview,
                        })
                        .then((response) => {
                          setInterview(response.data);
                          setCurrentOutput({
                            key: "",
                            data_type: "List",
                            extraction_instructions: "",
                            editIndex: -1,
                          });
                          setSnackbarMsg(
                            "Structured Output changes saved successfully"
                          );
                          setOpenSnackbar(true);
                        })
                        .catch((error) => {
                          console.error(
                            "Failed to update interview structured outputs:",
                            error
                          );
                        });

                      setShowAddOutputInput(false);
                    }
                  }}
                >
                  Save
                  <ArrowForward
                    style={{ marginLeft: "8px", fontSize: "16px" }}
                  />
                </IconButton>
              </div>
            </DialogActions>
          </Dialog>
          <Dialog
            open={showOutputDeletionConfirmation}
            onClose={() => setShowOutputDeletionConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Structured Output Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this structured output? This
                action cannot be undone:
                {outputDeletionIndex > -1 && (
                  <p
                    style={{
                      fontSize: 16,
                      padding: "25px",
                      border: "0.5px solid darkgray",
                      borderRadius: "10px",
                    }}
                  >
                    <b>Key:</b>{" "}
                    {interview.structuredOutputs[outputDeletionIndex].key}
                    <br />
                    <br />
                    <b>Description:</b>{" "}
                    {
                      interview.structuredOutputs[outputDeletionIndex]
                        .extraction_instructions
                    }
                    <br />
                    <br />
                    <b>Data Type:</b>{" "}
                    {interview.structuredOutputs[outputDeletionIndex].data_type}
                  </p>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="btn outlined"
                onClick={() => setShowOutputDeletionConfirmation(false)}
                color="primary"
              >
                Close
              </Button>
              <Button
                className="btn outlined danger"
                onClick={() => {
                  const tempInterview = { ...interview };
                  tempInterview.structuredOutputs.splice(
                    outputDeletionIndex,
                    1
                  );
                  fetch(`${proxyEndpoint}/interviews/${interview._id}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      interview: tempInterview,
                    }),
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      setInterview(data);
                      setSnackbarMsg("Structured Output has been deleted!");
                      setOpenSnackbar(true);
                      console.log("Structured Output deleted successfully");
                    })
                    .catch((error) => {
                      console.error("Error deleting structured output:", error);
                    });
                  setOutputDeletionIndex(-1);
                  setShowOutputDeletionConfirmation(false);
                }}
                color="primary"
                autoFocus
              >
                Delete!
              </Button>
            </DialogActions>
          </Dialog>
        </DndContext>
      </div>
    );
  };

  const renderScorecards = () => {
    return (
      <div>
        {currentScorecards.map((scorecard) => {
          return (
            <>
              <h4>{scorecard.title}</h4>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Skill</TableCell>
                    <TableCell>Feedback</TableCell>
                    <TableCell>Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scorecard.results.map((scorecardItem, index) => (
                    <TableRow key={index}>
                      <TableCell>{scorecardItem.signal}</TableCell>
                      <TableCell>{scorecardItem.feedback}</TableCell>
                      <TableCell>{scorecardItem.score}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          );
        })}
      </div>
    );
  };

  const deleteInterviewConfiguration = () => {
    return axios
      .post(proxyEndpoint + "/interviews/delete", { id: interview._id })
      .then((response) => {
        navigate("/interviews");
        setSnackbarMsg("Interview configuration has been deleted!");
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.error(err);
        // handle error here
      });
  };

  const onDeleteInterviewConfiguration = () => {
    setShowDeleteModal(true);
  };

  const voiceClicked = (voice) => {
    axios
      .post(proxyEndpoint + "/interviews/" + interview._id, {
        interview: {
          ...interview,
          voice: voice,
        },
      })
      .then((response) => {
        if (response.data.message) {
          setSnackbarMsg(response.data.message);
          setOpenSnackbar(true);
          return;
        }
        setInterview(response.data);
        setCurrentVoice(voice);
        setSnackbarMsg(`Voice has been set to ${voice.voice_name}.`);
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.error(err);
        // handle error here
      });
  };

  const renderSettings = () => {
    return (
      <div style={{ padding: "16px", width: "80%", margin: "auto " }}>
        <p
          className="section"
          style={{
            fontSize: "22px",
            fontWeight: 600,
            paddingBottom: 0,
            marginBottom: 0,
          }}
        >
          Settings
        </p>
        <p style={{ fontSize: "14px", color: "#475467", paddingTop: 0 }}>
          Update your settings here
        </p>
        <br />
        <br />
        <Grid container spacing={2}>
          {/* Title */}
          <Box
            className="d-flex flex-col section"
            style={{ marginLeft: "15px" }}
          >
            <FormField
              label="Interview Title"
              value={newInterviewTitle}
              style={{ width: "400px" }}
              onChange={(e) => setNewInterviewTitle(e.target.value)}
            />
            <button
              className="btn-main my-aut`o"
              style={{ width: "fit-content" }}
              onClick={() => {
                axios
                  .post(proxyEndpoint + "/interviews/" + interview._id, {
                    interview: {
                      ...interview,
                      title: newInterviewTitle,
                    },
                  })
                  .then((response) => {
                    if (response.data.message) {
                      setSnackbarMsg(response.data.message);
                      setOpenSnackbar(true);
                      return;
                    }
                    setInterview(response.data);
                    setSnackbarMsg("Interview title has been updated!");
                    setOpenSnackbar(true);
                  })
                  .catch((err) => {
                    console.error(err);
                    // handle error here
                  });
              }}
            >
              Save
            </button>
          </Box>

          <Grid item xs={12}>
            <h3 className="section" style={{ marginBottom: "5px" }}>
              Interviewer Voice
            </h3>
            <p
              className="color-gray"
              style={{ marginTop: "0px", fontSize: "14px" }}
            >
              Change the voice profile of the AI interviewer.
            </p>

            <Grid item xs={4}>
              <VoiceSelector
                currentVoice={currentVoice}
                voices={voices}
                clicked={voiceClicked}
              ></VoiceSelector>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "25px" }}>
            <div className="d-flex flex-row">
              <h3 className="my-auto">Dynamic Interview Link</h3>
            </div>
            <p className="color-gray" style={{ fontSize: 14 }}>
              Share this link with candidates to let them enter their details
              and start the interview.
            </p>

            <div className="d-flex" style={{ marginTop: "16px" }}>
              <TextField
                fullWidth
                value={dynamicLink}
                InputProps={{
                  readOnly: true,
                  style: {
                    fontFamily: "PPNeueMontreal",
                    fontSize: "14px",
                    background: "#FAFAFA",
                  },
                }}
                sx={{ mr: 2 }}
              />
              <Button
                className="btn-main"
                onClick={handleCopyDynamicLink}
                style={{
                  height: "46px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {showCopySuccess ? "Copied!" : "Copy Link"}
              </Button>
            </div>
          </Grid>

          <Snackbar
            open={showCopySuccess}
            autoHideDuration={2000}
            onClose={() => setShowCopySuccess(false)}
            message="Link copied to clipboard"
          />

          {/* <Grid item xs={12}>
            <h3 className="section" style={{ marginBottom: '5px' }}>Default video recorder</h3>
            <p className="color-gray" style={{ marginTop: '0px', fontSize: '14px' }}>You can select which tool you'd like to use as your default recorder. If neither is selected, the built-in option is used.</p>
          </Grid>
          <Grid item xs={12}>
            <Grid container className="video-recorder-select" style={{ width: '100%', borderRadius: '6px', border: '1px solid #EAECF0', padding: '10px', height: '92px', border: videoRecorder === 'loom' ? '1px solid black' : '1px solid #EAECF0' }} onClick={() => {setVideoRecorder('loom')}}>
              <Grid item xs={1}>
                <img src={LoomLogoSvg} style={{ marginTop: '25px' }} />
              </Grid>
              <Grid item xs={10}>
                <Grid item xs={12}>
                  <p style={{ fontSize: '14px', marginTop: '7px', marginBottom: '0px', fontWeight: '600' }}>Loom</p>
                </Grid>
                <Grid item xs={12}>
                  <p style={{ fontSize: '12px', marginTop: '2px', marginBottom: '0px' }}>Record candidate video via Loom</p>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox style={{ marginLeft: 0, paddingLeft: 0 }} checked={videoRecorder === 'loom'}
                    onSelect={() => {setVideoRecorder('loom')}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

          {/* <Grid item xs={12}>
            <Grid container className="video-recorder-select" style={{ width: '100%', borderRadius: '6px', padding: '10px', height: '92px', border: videoRecorder === 'builtin' ? '1px solid black' : '1px solid #EAECF0' }} onClick={() => {setVideoRecorder('builtin')}}>
              <Grid item xs={1}>
                <img src={CamLogoSvg} style={{ marginTop: '25px' }} />
              </Grid>
              <Grid item xs={10}>
                <Grid item xs={12}>
                  <p style={{ fontSize: '14px', marginTop: '7px', marginBottom: '0px', fontWeight: '600' }}>Built-in</p>
                </Grid>
                <Grid item xs={12}>
                  <p style={{ fontSize: '12px', marginTop: '2px', marginBottom: '0px'  }}>Uses the standard system video recorder</p>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox style={{ marginLeft: 0, paddingLeft: 0 }} checked={videoRecorder === 'builtin'}
                    onSelect={() => {setVideoRecorder('builtin')}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        <br />
        <br />

        <Box className="d-flex flex-col section">
          <h3 className="section">Candidate Onboarding Practice Chat</h3>
          <Grid item xs={12}>
            <Grid
              container
              className="video-recorder-select"
              style={{
                width: "100%",
                borderRadius: "6px",
                padding: "10px",
                height: "110px",
                border: practiceChatEnabled
                  ? "1px solid black"
                  : "1px solid #EAECF0",
              }}
              onClick={() => {
                setPracticeChatEnabled(!practiceChatEnabled);
              }}
            >
              <Grid item xs={10}>
                <Grid item xs={12}>
                  <p
                    style={{
                      fontSize: "14px",
                      marginTop: "7px",
                      marginBottom: "0px",
                      fontWeight: "600",
                    }}
                  >
                    Practice Chat Enabled
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "2px",
                      marginBottom: "0px",
                    }}
                  >
                    Runs the candidate through a 30 second practice session with
                    Steve during onboarding before the start of the real
                    interview.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    style={{ marginLeft: 0, paddingLeft: 0 }}
                    checked={practiceChatEnabled}
                    onSelect={() => {
                      setPracticeChatEnabled(!practiceChatEnabled);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12}>
            <button
              className="btn-main my-auto"
              style={{ width: "fit-content" }}
              onClick={() => {
                axios
                  .post(proxyEndpoint + "/interviews/" + interview._id, {
                    interview: {
                      ...interview,
                      practiceChatEnabled: practiceChatEnabled,
                    },
                  })
                  .then((response) => {
                    if (response.data.message) {
                      setSnackbarMsg(response.data.message);
                      setOpenSnackbar(true);
                      return;
                    }
                    setInterview(response.data);
                    setSnackbarMsg(
                      `Practice chat has been ${
                        practiceChatEnabled ? "enabled" : "disabled"
                      }!`
                    );
                    setOpenSnackbar(true);
                  })
                  .catch((err) => {
                    console.error(err);
                    // handle error here
                  });
              }}
            >
              Save
            </button>
          </Grid>
        </Box>

        <br />
        <br />

        <h3 className="section">Custom Instructions</h3>
        <Box className="d-flex flex-col section">
          <FormField
            label="Interview Prompt"
            tooltip
            tooltipTitle={
              <>
                <p className="m-auto">
                  What will be used for conversing with the candidate.
                </p>
              </>
            }
            value={newPrompt}
            multiline
            minRows={8}
            onChange={(e) => {
              setNewPrompt(e.target.value);
            }}
          />
          <button
            className="btn-main my-auto"
            style={{ width: "fit-content" }}
            onClick={onUpdatePrompt}
          >
            Save
          </button>
        </Box>

        <Box className="d-flex flex-col section">
          <FormField
            label="Evaluation Prompt"
            tooltip
            tooltipTitle={
              <>
                <p className="m-auto">
                  What will be used for scoring the candidate once the candidate
                  session has ended.
                </p>
              </>
            }
            value={newEvalPrompt}
            multiline
            minRows={8}
            onChange={(e) => {
              setNewEvalPrompt(e.target.value);
            }}
          />
          <button
            className="btn-main my-auto"
            style={{ width: "fit-content" }}
            onClick={onUpdateEvalPrompt}
          >
            Save
          </button>
        </Box>

        <Box className="d-flex flex-col section">
          <h3 className="section">Max Interview Length (minutes)</h3>
          <FormField
            label="Max Interview Length"
            value={newInterviewLength}
            style={{ width: "400px" }}
            onChange={(e) => setNewInterviewLength(e.target.value)}
          />
          <button
            className="btn-main my-auto"
            style={{ width: "fit-content" }}
            onClick={() => {
              axios
                .post(proxyEndpoint + "/interviews/" + interview._id, {
                  interview: {
                    ...interview,
                    length: newInterviewLength,
                  },
                })
                .then((response) => {
                  if (response.data.message) {
                    setSnackbarMsg(response.data.message);
                    setOpenSnackbar(true);
                    return;
                  }
                  setInterview(response.data);
                  setSnackbarMsg("Interview length has been updated!");
                  setOpenSnackbar(true);
                })
                .catch((err) => {
                  console.error(err);
                  // handle error here
                });
            }}
          >
            Save
          </button>
        </Box>
        <Box className="d-flex flex-col section">
          <h3 className="section">Add a Phone Number for Inbound Calls</h3>
          <p
            className="color-gray"
            style={{ marginTop: "0px", fontSize: "14px" }}
          >
            This will allow you to receive inbound calls for this interview.
            Share this number with candidates, and they can call it to start the
            interview.
          </p>
          {interview.phone_number && (
            <>
              <FormField
                label="Phone Number"
                value={interview.phone_number}
                style={{ width: "400px" }}
                disabled
              />
              <button
                className="btn-main my-auto"
                style={{ width: "fit-content" }}
                disabled={generatingPhoneNumber}
                onClick={() => {
                  setGeneratingPhoneNumber(true);
                  axios
                    .post(
                      proxyEndpoint +
                        "/interviews/" +
                        interview._id +
                        "/generate-phone-number",
                      {
                        interview: {
                          ...interview,
                          phone_number: interview.phone_number,
                        },
                      }
                    )
                    .then((response) => {
                      setInterview({
                        ...interview,
                        phone_number: response.data.phone_number,
                      });
                      setSnackbarMsg("Phone number has been regenerated!");
                      setOpenSnackbar(true);
                    })
                    .finally(() => {
                      setGeneratingPhoneNumber(false);
                    });
                }}
              >
                {generatingPhoneNumber
                  ? "Generating..."
                  : "Regenerate Phone Number"}
              </button>
            </>
          )}
          {!interview.phone_number && (
            <button
              className="btn-main my-auto"
              style={{ width: "fit-content" }}
              disabled={generatingPhoneNumber}
              onClick={() => {
                setGeneratingPhoneNumber(true);
                axios
                  .post(
                    proxyEndpoint +
                      "/interviews/" +
                      interview._id +
                      "/generate-phone-number",
                    {}
                  )
                  .then((response) => {
                    setInterview({
                      ...interview,
                      phone_number: response.data.phone_number,
                    });
                    setSnackbarMsg("Phone number has been generated!");
                    setOpenSnackbar(true);
                  })
                  .finally(() => {
                    setGeneratingPhoneNumber(false);
                  });
              }}
            >
              {generatingPhoneNumber
                ? "Generating..."
                : "Generate Phone Number"}
            </button>
          )}
        </Box>

        {process.env.REACT_APP_BULLHORN_ENABLED === "true" && (
          <Box container className="d-flex flex-col section">
            <h3 className="section">ATS Integration Settings</h3>
            <FormField
              label="Bullhorn Job ID"
              type="number"
              value={interview.bullhornJobId}
              style={{ width: "400px" }}
              onChange={(e) => {
                setInterview({
                  ...interview,
                  bullhornJobId: parseInt(e.target.value),
                });
              }}
            />
            <p
              className="color-gray"
              style={{ marginTop: "0px", fontSize: "14px" }}
            >
              If you have connected Bullhorn to Wayfaster and want us to track new
              Web Responses for the Job associated with this interview, enter the
              Bullhorn Job ID here. From then onwards, Wayfaster interview links
              will automatically appear in new Candidate profiles for this Job.
            </p>
            <button
              className="btn-main"
              style={{ width: "fit-content" }}
              onClick={() => {
                api
                  .updateInterview(interview._id, {
                    ...interview,
                    bullhornJobId: interview.bullhornJobId,
                  })
                  .then(() => {
                    setSnackbarMsg("Bullhorn Job ID has been saved!");
                    setOpenSnackbar(true);
                  })
                  .catch((error) => {
                    console.error("Error saving Bullhorn Job ID:", error);
                    setSnackbarMsg("Error saving Bullhorn Job ID");
                    setOpenSnackbar(true);
                  });
              }}
            >
              Save
            </button>
          </Box>
        )}

        <button
          className="btn-main outlined danger"
          style={{ marginTop: "24px" }}
          onClick={onDeleteInterviewConfiguration}
        >
          Delete Interview
        </button>
      </div>
    );
  };

  const passSession = (session) => {
    if (
      !session.scorecard ||
      session.scorecard.length === 0 ||
      !session.metadata.interview_config ||
      !session.scorecard
    )
      return -1;

    // Check if weights add up to 100, if not, set them to equal weights
    const totalWeightCheck = session.scorecard.reduce(
      (sum, item) => sum + (item.weight || 0),
      0
    );
    if (Math.abs(totalWeightCheck - 100) > 0.001) {
      const equalWeight = 25;
      session.scorecard.forEach((item) => (item.weight = equalWeight));
    }

    let weightedSum = 0;
    let totalWeight = 0;

    session.scorecard.forEach((scorecardItem) => {
      const configItem = session.scorecard.find(
        (item) => item.signal === scorecardItem.signal
      );
      if (configItem) {
        const weight = configItem.weight / 100 || 0;
        weightedSum += scorecardItem.score * weight;
        totalWeight += weight;
      }
    });

    if (totalWeight === 0) return -1;

    const weightedAverage = weightedSum / totalWeight;
    const avgFixed = weightedAverage.toFixed(1);
    return avgFixed >= 2.5;
  };

  /**
   * Navigates URL to interviews/[_id] slug and sets relevant
   * flags to trigger destination interview re-fetch.
   */
  const navigateAndPopulateInterview = (interviewId) => {
    // set bools within useEffect dependency array to trigger re-fetch
    setGetInterviewCalled(false);
    setGetCompletedSessionsCalled(false);

    // reset some UI
    setPreviewOpen(false);
    setCurrentPreviewQuestion({});
    setInterviewPreview([]);

    navigate("/interviews/" + interviewId);
  };

  const handleOnCopy = (interviewConfigId) => {
    console.log("handleOnCopy", interviewConfigId);
    navigate(`/create-interview?copy_from=${interviewConfigId}`);
  };

  // Custom "Basic" styling override of MUI Menu component
  const MenuBasic = styled((props) => <Menu {...props} />)(({ theme }) => ({
    "& .MuiPaper-root": {
      boxShadow: "none !important",
    },
  }));

  const handleSortCompleted = (sessions, order) => {
    setCompletedOrder(order);
    setOrderBy("completed");

    let sortedSessions = [...sessions];

    sortedSessions.sort((a, b) => {
      const aTimestamp = a.date_completed_unix_timestamp || 0;
      const bTimestamp = b.date_completed_unix_timestamp || 0;

      if (order === "asc") {
        return aTimestamp - bTimestamp;
      } else {
        return bTimestamp - aTimestamp;
      }
    });

    setFilteredSessions(sortedSessions);
  };

  const handleSortInvited = () => {
    let tmpOrder = invitedOrder === "asc" ? "desc" : "asc";
    setInvitedOrder(tmpOrder);
    setOrderBy("invited");

    let sortedSessions = [...filteredSessions];

    sortedSessions.sort((a, b) => {
      const aStatus = a.status;
      const bStatus = b.status;
      const aTimestamp =
        a.client_type === "phone" || a.client_type === "mobile"
          ? a.created_at * 1000
          : new Date(a.created_at.$date).getTime();
      const bTimestamp =
        b.client_type === "phone" || b.client_type === "mobile"
          ? b.created_at * 1000
          : new Date(b.created_at.$date).getTime();

      if (tmpOrder === "asc") {
        // For ascending order, always start with in-progress ones
        if (
          aStatus === SessionStatus.IN_PROGRESS &&
          bStatus !== SessionStatus.IN_PROGRESS
        )
          return -1;
        if (
          bStatus === SessionStatus.IN_PROGRESS &&
          aStatus !== SessionStatus.IN_PROGRESS
        )
          return 1;
        return aTimestamp - bTimestamp;
      } else {
        // For descending order
        if (
          aStatus === SessionStatus.NOT_STARTED &&
          bStatus !== SessionStatus.NOT_STARTED
        )
          return 1;
        if (
          bStatus === SessionStatus.NOT_STARTED &&
          aStatus !== SessionStatus.NOT_STARTED
        )
          return -1;
        return bTimestamp - aTimestamp;
      }
    });

    setFilteredSessions(sortedSessions);
  };

  const handleSortStatus = () => {
    let tmpOrder = statusOrder === "asc" ? "desc" : "asc";
    setStatusOrder(tmpOrder);

    let sortedSessions = [];
    var tmpSessions = filteredSessions;

    if (tmpOrder === "desc") {
      // First, add completed and accepted sessions (excluding voicemail)
      sortedSessions = tmpSessions.filter(
        (session) =>
          session.status === SessionStatus.COMPLETED &&
          passSession(session) &&
          !session.voicemail_detected
      );

      // Then, add completed and rejected sessions (excluding voicemail)
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(
          (session) =>
            session.status === SessionStatus.COMPLETED &&
            !passSession(session) &&
            !session.voicemail_detected
        )
      );

      // Finally, add in-progress sessions
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(
          (session) => session.status === SessionStatus.INCOMPLETE
        )
      );

      sortedSessions = sortedSessions.concat(
        completedSessions.filter(
          (session) => session.status === SessionStatus.NOT_STARTED
        )
      );
    } else {
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(
          (session) => session.status === SessionStatus.NOT_STARTED
        )
      );

      // Finally, add in-progress sessions
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(
          (session) => session.status === SessionStatus.INCOMPLETE
        )
      );

      // Then, add completed and rejected sessions (excluding voicemail)
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(
          (session) =>
            session.status === SessionStatus.COMPLETED &&
            !passSession(session) &&
            !session.voicemail_detected
        )
      );

      // Finally, add completed and accepted sessions (excluding voicemail)
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(
          (session) =>
            session.status === SessionStatus.COMPLETED &&
            passSession(session) &&
            !session.voicemail_detected
        )
      );
    }

    setFilteredSessions(sortedSessions);
  };

  function interviewSort(a, b) {
    // top_val comes first
    if (a.type && a.type == "template") return 1;
    if (b.type && b.type == "template") return -1;
    // neither matches `value` - compare them normally
    else return 0;
  }

  const handleSortScore = () => {
    let tmpOrder = scoreOrder;
    if (scoreOrder === "asc") {
      setScoreOrder("desc");
      tmpOrder = "desc";
    } else {
      setScoreOrder("asc");
      tmpOrder = "asc";
    }

    let sortedSessions = [];
    completedSessions.map((session, idx) => {
      const isPass = passSession(session);
      if (isPass == null) {
        sortedSessions.push(session);
      }
    });

    completedSessions.map((session, idx) => {
      const isPass = passSession(session);
      if (isPass === false) {
        sortedSessions.push(session);
      }
    });

    completedSessions.map((session, idx) => {
      const isPass = passSession(session);
      if (isPass) {
        sortedSessions.push(session);
      }
    });

    if (tmpOrder === "desc") {
      sortedSessions.reverse();
    }

    setCompletedSessions(sortedSessions);
  };

  const rerunAnalysis = (session, idx) => {
    console.log("rerunAnalysis", session);
    setRerunningIdxes([...rerunningIdxes, idx]);
    api
      .analyzeSessions({
        sessions: [
          {
            id: session._id.$oid,
            team_id: session.team_id,
            user_id: session.user_id,
            interview_config_id: session.interview_config_id,
            bullhorn_candidate_id:
              session?.candidate_metadata?.bullhorn_candidate_id || undefined,
          },
        ],
      })
      .then((response) => {
        setSnackbarMsg(
          "Analysis is currently running. Refresh page to see changes."
        );
        setOpenSnackbar(true);
        // setRerunningIdxes(rerunningIdxes.filter((index) => index !== idx))
      })
      .catch((error) => {
        setSnackbarMsg("Analysis has failed. Refresh page and try again.");
        setOpenSnackbar(true);
      });
  };

  const handleCreateInterview = () => {
    navigate("/create-interview");
  };

  const toggleSessionSelected = (session_id) => {
    let selected_sessions = selectedSessions;
    let idx = selected_sessions.findIndex((session) => session == session_id);
    if (idx == -1) {
      setSelectedSessions([...selectedSessions, session_id]);
    } else {
      selected_sessions.splice(idx, 1);
      setSelectedSessions([...selected_sessions]);
    }
  };

  const handleAnalyzeBulk = () => {
    let ids = [...selectedSessions];
    setRerunningIds([...rerunningIds, ...ids]);
    setRunningBulk(true);
    api
      .analyzeSessions({
        sessions: ids.map((id) => {
          const session = completedSessions.find((s) => s._id.$oid === id);
          return {
            id: session._id.$oid,
            team_id: session.team_id,
            user_id: session.user_id,
            interview_config_id: session.interview_config_id,
            bullhorn_candidate_id:
              session?.candidate_metadata?.bullhorn_candidate_id || undefined,
          };
        }),
      })
      .then((response) => {
        setSnackbarMsg(
          "Analysis has been re-run for " +
            `${ids.length} session${
              ids.length > 1 ? "s" : ""
            }. Refresh page to see changes.`
        );
        setOpenSnackbar(true);
        setRunningBulk(false);
        setRerunningIds(rerunningIds.filter((id) => !ids.includes(id)));
      })
      .catch((error) => {
        setSnackbarMsg(
          "Analysis has failed when re-run on " +
            `${ids.length} session${
              ids.length > 1 ? "s" : ""
            }. Refresh page to see changes.`
        );
        setOpenSnackbar(true);
        setRunningBulk(false);
      });
  };

  const populateOnNavigate = () => {
    setGetInterviewCalled(false);
    setGetCompletedSessionsCalled(false);
    
    // trigger loading state
    setNavigatedLoading(true);
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEndSessionSpecific}
      sensors={sensors}
    >
      <div className="w-100">
        <Box sx={{ display: "flex" }}>
          {isAuthenticated && (
            <Sidebar
              user={user}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              onNavigate={populateOnNavigate}
              onNextInterviewSet={setNextInterviewId}
              onPreviousInterviewSet={setPreviousInterviewId}
            ></Sidebar>
          )}

          {!interview.title && (
            <>
              {!getInterviewReturned ? (
                // Empty loading state before fetch
                <Box className="d-flex flex-col" style={{ padding: 25, width: "100%", margin: "auto" }}>
                  <Box className="d-flex flex-col">
                    <Skeleton variant="text" width={180} height={30} />
                    <Skeleton variant="text" width={100} height={18} />
                  </Box>
                  <Box className="d-flex flex-row" style={{ marginTop: '12px', gap: '12px' }}>
                    <Skeleton variant="text" width={90} height={40} />
                    <Skeleton variant="text" width={90} height={40} />
                    <Skeleton variant="text" width={90} height={40} />
                    <Skeleton variant="text" width={90} height={40} />
                  </Box>
                  <Skeleton variant="rectangular" width="100%" height={200} style={{ borderRadius: '12px', marginTop: '24px' }}/>
                </Box>
              ) : (
                // Empty state after fetch
                <Box
                  style={{
                    padding: 25,
                    background: "white",
                    borderRadius: 6,
                    width: "70%",
                    margin: "auto",
                    marginTop: "100px",
                  }}
                >
                  {interviews.length === 0 && (
                    <>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        height="457px"
                        style={{
                          borderRadius: "8px",
                          background: "#F7F7F7",
                          marginLeft: "15px",
                        }}
                      >
                        <Assignment
                          style={{ fontSize: "92px", color: "gray" }}
                        />
                        <p
                          style={{
                            color: "#ACACAC",
                            fontSize: "18px",
                            fontFamily: "PPNeueMontreal",
                            weight: 600,
                            marginBottom: 0,
                          }}
                        >
                          You haven't created any interviews yet.
                        </p>
                        <p
                          style={{
                            color: "#ACACAC",
                            fontSize: "14px",
                            fontWeight: 400,
                            fontFamily: "PPNeueMontreal",
                            marginBottom: "25px",
                          }}
                        >
                          Get started by creating a new interview below.
                        </p>
                        <button
                          className="btn-main"
                          style={{ fontSize: "12px" }}
                          onClick={handleCreateInterview}
                        >
                          Get started
                        </button>
                      </Box>
                    </>
                  )}
                </Box>
              )}
            </>
          )}

          {interview.title && (
            <>
              <Box
                style={{
                  background: "#FAFAFA",
                  width: "100%",
                  margin: "auto",
                  paddingBottom: "96px",
                }}
              >
                {/* Interview Header */}
                <Box
                  style={{
                    width: "100%",
                    background: "white",
                    padding: "24px 32px 16px 32px",
                  }}
                >
                  <div className="d-flex flex-row" style={{ width: "100%" }}>
                    <Box className="d-flex" style={{ background: "white" }}>
                      {!navigatedLoading ? (
                        <Box className="d-flex flex-col">
                          <Box className="d-flex flex-row">
                            <h3
                              className="my-auto"
                              style={{ marginRight: "8px" }}
                            >
                              {interview.title}
                            </h3>
                            {interview.type && interview.type == "template" ? (
                              <ChipTag variant="action" text="Template" />
                            ) : (
                              <ChipTag
                                variant="in-progress"
                                text="In Progress"
                              />
                            )}
                          </Box>
                          <h5
                            className="color-gray my-auto"
                            style={{ fontWeight: 500, marginTop: "6px" }}
                          >
                            {new Date(interview.createdAt).toDateString()}
                          </h5>
                        </Box>
                      ) : (
                        <Box className="d-flex flex-col">
                          <Skeleton variant="text" width={180} height={30} />
                          <Skeleton variant="text" width={100} height={18} />
                        </Box>
                      )}
                    </Box>

                    {/* Interview Previous / Next */}
                    <div
                      className="d-flex flex-row"
                      style={{ marginLeft: "auto", height: "24px" }}
                    >
                      <div
                        className="d-flex flex-row"
                        style={{ marginLeft: "auto" }}
                      >
                        <button
                          className={`btn-main small d-flex gray outlined ${
                            previousInterviewId ? "" : "inactive"
                          }`}
                          disabled={!previousInterviewId}
                          onClick={() => {
                            navigateAndPopulateInterview(previousInterviewId);
                          }}
                        >
                          <NavigateBefore
                            fontSize="14px"
                            className="color-heading m-auto"
                          />
                        </button>
                        <h5
                          onClick={() => {
                            navigateAndPopulateInterview(previousInterviewId);
                          }}
                          className={[
                            previousInterviewId ? "active-link" : "inactive",
                          ]}
                          style={{ margin: "auto 8px", lineHeight: "20px" }}
                        >
                          Previous Interview
                        </h5>

                        <h5
                          className="inactive"
                          style={{ margin: "auto 4px", lineHeight: "20px" }}
                        >
                          |
                        </h5>

                        <h5
                          onClick={() => {
                            navigateAndPopulateInterview(nextInterviewId);
                          }}
                          className={[
                            nextInterviewId ? "active-link" : "inactive",
                          ]}
                          style={{ margin: "auto 8px", lineHeight: "20px" }}
                        >
                          Next Interview
                        </h5>

                        <button
                          className={`btn-main small d-flex gray outlined ${
                            nextInterviewId ? "" : "inactive"
                          }`}
                          disabled={!nextInterviewId}
                          onClick={() => {
                            navigateAndPopulateInterview(nextInterviewId);
                          }}
                        >
                          <NavigateNext
                            fontSize="14px"
                            className="color-heading m-auto"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </Box>

                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    background: "white",
                  }}
                >
                  <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    disableRipple
                    style={{ padding: "0px 32px", background: "white" }}
                    sx={{
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#0D0E14",
                      },
                    }}
                  >
                    <Tab
                      label="Sessions"
                      value="sessions"
                      disableRipple
                      style={{
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: currentTab === "sessions" ? 600 : 500,
                        fontFamily: "PPNeueMontreal",
                        color: currentTab === "sessions" ? "black" : "darkgray",
                        zIndex: currentTab === "sessions" ? 10 : 0,
                      }}
                    />
                    <Tab
                      label="Questions"
                      value="questions"
                      disableRipple
                      style={{
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: currentTab === "questions" ? 600 : 500,
                        fontFamily: "PPNeueMontreal",
                        color: currentTab === "questions" ? "black" : "darkgray",
                        zIndex: currentTab === "questions" ? 10 : 0,
                      }}
                    />
                    <Tab
                      label="Scorecard"
                      value="scorecard"
                      disableRipple
                      style={{
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: currentTab === "scorecard" ? 600 : 500,
                        fontFamily: "PPNeueMontreal",
                        color: currentTab === "scorecard" ? "black" : "darkgray",
                        zIndex: currentTab === "scorecard" ? 10 : 0,
                      }}
                    />
                    {process.env.REACT_APP_INBOUND_OUTBOUND_ENABLED === "true" && (
                      <Tab
                        label="Inbound & Outbound"
                        value="inbound-outbound"
                        disableRipple
                        style={{
                          textTransform: "none",
                          fontSize: "14px",
                          fontWeight: currentTab === "inbound-outbound" ? 600 : 500,
                          fontFamily: "PPNeueMontreal",
                          color: currentTab === "inbound-outbound" ? "black" : "darkgray",
                          zIndex: currentTab === "inbound-outbound" ? 10 : 0,
                        }}
                      />
                    )}
                    <Tab
                      label="Structured Outputs"
                      value="structured-outputs"
                      disableRipple
                      style={{
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: currentTab === "structured-outputs" ? 600 : 500,
                        fontFamily: "PPNeueMontreal",
                        color: currentTab === "structured-outputs" ? "black" : "darkgray",
                        zIndex: currentTab === "structured-outputs" ? 10 : 0,
                      }}
                    />
                    <Tab
                      label="Settings"
                      value="settings"
                      disableRipple
                      style={{
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: currentTab === "settings" ? 600 : 500,
                        fontFamily: "PPNeueMontreal",
                        color: currentTab === "settings" ? "black" : "darkgray",
                        zIndex: currentTab === "settings" ? 10 : 0,
                      }}
                    />
                  </Tabs>
                </Box>

                {!navigatedLoading ? (
                  <Box
                    style={{
                      margin: "24px 32px",
                      background: "white",
                      border: "1px solid lightgray",
                      borderRadius: "12px",
                    }}
                  >
                    {currentTab === "sessions" && (
                      <>
                        {(!interview.type || interview.type != "template") && (
                          <div
                            className="d-flex flex-row"
                            style={{
                              padding: "12px 16px",
                              borderBottom: "1px solid lightgray",
                              marginBottom: 0,
                            }}
                          >
                            <div style={{ display: "flex", gap: "8px" }}>
                              <Button
                                onClick={() => {
                                  setFilteredSessions(completedSessions);
                                  setSessionFilter(null);

                                  if (completedOrder === "desc") {
                                    handleSortCompleted(
                                      completedSessions,
                                      completedOrder
                                    );
                                  } else if (invitedOrder === "desc") {
                                    handleSortInvited();
                                  } else if (statusOrder === "desc") {
                                    handleSortStatus();
                                  }
                                }}
                                style={{
                                  backgroundColor:
                                    sessionFilter === null
                                      ? "var(--color-lightgray)"
                                      : "#fff",
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  padding: "4px 8px",
                                  textTransform: "none",
                                  color: "var(--color-main)",
                                }}
                                disabled={completedSessions.length === 0}
                              >
                                {!getCompletedSessionsCalled ? (
                                  <>
                                    All Sessions &nbsp; 
                                    (<CircularProgress size={16} />)
                                  </>
                                ) : (
                                  `All Sessions (${completedSessions.length})`
                                )}
                              </Button>

                              <Button
                                onClick={() => {
                                  const filtered = completedSessions.filter(
                                    (session) =>
                                      session.status === SessionStatus.COMPLETED
                                  );
                                  setFilteredSessions(filtered);
                                  setSessionFilter("completed");
                                }}
                                style={{
                                  backgroundColor:
                                    sessionFilter === "completed"
                                      ? "var(--color-completed-bg)"
                                      : "#fff",
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  padding: "4px 8px",
                                  textTransform: "none",
                                  color: "var(--color-main)",
                                }}
                                disabled={completedSessions.length === 0}
                              >
                                <Circle
                                  className="m-auto color-completed"
                                  style={{ fontSize: 8, marginRight: "4px" }}
                                />
                                Completed (
                                {!getCompletedSessionsCalled ? 
                                  <>
                                    <CircularProgress size={16} />
                                  </>
                                : (
                                  completedSessions.length > 0
                                    ? completedSessions.filter(
                                        (session) =>
                                          session.status ===
                                          SessionStatus.COMPLETED
                                      ).length
                                    : 0
                                )}
                                )
                              </Button>

                              <Button
                                onClick={() => {
                                  const filtered = completedSessions.filter(
                                    (session) =>
                                      session.status ===
                                        SessionStatus.COMPLETED &&
                                      (passSession(session) ||
                                        session.customerOverrideCandidateAction ===
                                          "accept") &&
                                      !session.voicemail_detected &&
                                      session.scorecard &&
                                      session.scorecard.length > 0
                                  );
                                  setFilteredSessions(filtered);
                                  setSessionFilter("accepted");
                                }}
                                style={{
                                  backgroundColor:
                                    sessionFilter === "accepted"
                                      ? "var(--color-accept-bg)"
                                      : "#fff",
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  padding: "4px 8px",
                                  textTransform: "none",
                                  color: "var(--color-main)",
                                }}
                                disabled={completedSessions.length === 0}
                              >
                                <Circle
                                  className="m-auto color-accept"
                                  style={{ fontSize: 8, marginRight: "4px" }}
                                />
                                Accepted (
                                {!getCompletedSessionsCalled ? 
                                  <CircularProgress size={16} />
                                : completedSessions.length > 0
                                  ? completedSessions.filter(
                                      (session) =>
                                        session.status ===
                                          SessionStatus.COMPLETED &&
                                        (passSession(session) ||
                                          session.customerOverrideCandidateAction ===
                                            "accept") &&
                                        !session.voicemail_detected &&
                                        session.scorecard &&
                                        session.scorecard.length > 0
                                    ).length
                                  : 0}
                                )
                              </Button>

                              <Button
                                onClick={() => {
                                  const filtered = completedSessions.filter(
                                    (session) =>
                                      session.status ===
                                        SessionStatus.COMPLETED &&
                                      !passSession(session) &&
                                      session.customerOverrideCandidateAction !==
                                        "accept" &&
                                      !session.voicemail_detected
                                  );
                                  setFilteredSessions(filtered);
                                  setSessionFilter("reject");
                                }}
                                style={{
                                  backgroundColor:
                                    sessionFilter === "reject"
                                      ? "var(--color-reject-bg)"
                                      : "#fff",
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  padding: "4px 8px",
                                  textTransform: "none",
                                  color: "var(--color-main)",
                                }}
                                disabled={completedSessions.length === 0}
                              >
                                <Circle
                                  className="m-auto color-reject"
                                  style={{ fontSize: 8, marginRight: "4px" }}
                                />
                                Rejected (
                                {!getCompletedSessionsCalled ? 
                                  <CircularProgress size={16} />
                                : completedSessions.length > 0
                                  ? completedSessions.filter(
                                      (session) =>
                                        session.status ===
                                          SessionStatus.COMPLETED &&
                                        !passSession(session) &&
                                        session.customerOverrideCandidateAction !==
                                          "accept" &&
                                        !session.voicemail_detected
                                    ).length
                                  : 0}
                                )
                              </Button>

                              <Button
                                onClick={(event) =>
                                  setInProgressEl(event.currentTarget)
                                }
                                style={{
                                  backgroundColor:
                                    sessionFilter === "in-progress"
                                      ? "#E9D5FF"
                                      : sessionFilter === "incomplete"
                                      ? "#F3E8FF"
                                      : sessionFilter === "not-started"
                                      ? "#FAF5FF"
                                      : "#fff",
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  padding: "4px 8px",
                                  textTransform: "none",
                                  color: "var(--color-main)",
                                }}
                                disabled={completedSessions.length === 0}
                              >
                                <Circle
                                  className="m-auto"
                                  style={{
                                    fontSize: 8,
                                    marginRight: "4px",
                                    color:
                                      sessionFilter === "in-progress"
                                        ? "#9333EA"
                                        : sessionFilter === "incomplete"
                                        ? "#A855F7"
                                        : sessionFilter === "not-started"
                                        ? "#C084FC"
                                        : "#A855F7",
                                  }}
                                />
                                {sessionFilter === "in-progress"
                                  ? "Running"
                                  : sessionFilter === "incomplete"
                                  ? "Incomplete"
                                  : sessionFilter === "not-started"
                                  ? "Not Started"
                                  : "In Progress"}{" "}
                                (
                                {!getCompletedSessionsCalled ? 
                                  <CircularProgress size={16} />
                                : completedSessions.length > 0
                                  ? completedSessions.filter((session) =>
                                      sessionFilter === "in-progress"
                                        ? session.status ===
                                          SessionStatus.IN_PROGRESS
                                        : sessionFilter === "incomplete"
                                        ? session.status ===
                                          SessionStatus.INCOMPLETE
                                        : sessionFilter === "not-started"
                                        ? session.status ===
                                          SessionStatus.NOT_STARTED
                                        : session.status ===
                                            SessionStatus.IN_PROGRESS ||
                                          session.status ===
                                            SessionStatus.INCOMPLETE ||
                                          session.status ===
                                            SessionStatus.NOT_STARTED
                                    ).length
                                  : 0}
                                )
                                <KeyboardArrowDown
                                  sx={{ fontSize: "20px", marginLeft: "4px" }}
                                />
                              </Button>
                              <Menu
                                anchorEl={inProgressEl}
                                open={Boolean(inProgressEl)}
                                onClose={() => setInProgressEl(null)}
                              >
                                <MenuItem
                                  value="in-progress"
                                  onClick={() => {
                                    const filtered = completedSessions.filter(
                                      (session) =>
                                        session.status ===
                                        SessionStatus.IN_PROGRESS
                                    );
                                    setFilteredSessions(filtered);
                                    setSessionFilter("in-progress");
                                    setInProgressEl(null);
                                  }}
                                >
                                  <Circle
                                    className="m-auto"
                                    style={{
                                      fontSize: 8,
                                      marginRight: "4px",
                                      color: "#9333EA",
                                    }}
                                  />
                                  Running (
                                  {completedSessions.length > 0
                                    ? completedSessions.filter(
                                        (session) =>
                                          session.status ===
                                          SessionStatus.IN_PROGRESS
                                      ).length
                                    : 0}
                                  )
                                </MenuItem>
                                <MenuItem
                                  value="incomplete"
                                  onClick={() => {
                                    const filtered = completedSessions.filter(
                                      (session) =>
                                        session.status ===
                                        SessionStatus.INCOMPLETE
                                    );
                                    setFilteredSessions(filtered);
                                    setSessionFilter("incomplete");
                                    setInProgressEl(null);
                                  }}
                                >
                                  <Circle
                                    className="m-auto"
                                    style={{
                                      fontSize: 8,
                                      marginRight: "4px",
                                      color: "#A855F7",
                                    }}
                                  />
                                  Incomplete (
                                  {completedSessions.length > 0
                                    ? completedSessions.filter(
                                        (session) =>
                                          session.status ===
                                          SessionStatus.INCOMPLETE
                                      ).length
                                    : 0}
                                  )
                                </MenuItem>
                                <MenuItem
                                  value="not-started"
                                  onClick={() => {
                                    const filtered = completedSessions.filter(
                                      (session) =>
                                        session.status ===
                                        SessionStatus.NOT_STARTED
                                    );
                                    setFilteredSessions(filtered);
                                    setSessionFilter("not-started");
                                    setInProgressEl(null);
                                  }}
                                >
                                  <Circle
                                    className="m-auto"
                                    style={{
                                      fontSize: 8,
                                      marginRight: "4px",
                                      color: "#C084FC",
                                    }}
                                  />
                                  Not Started (
                                  {completedSessions.length > 0
                                    ? completedSessions.filter(
                                        (session) =>
                                          session.status ===
                                          SessionStatus.NOT_STARTED
                                      ).length
                                    : 0}
                                  )
                                </MenuItem>
                              </Menu>
                            </div>
                            <Button
                              className="d-flex"
                              style={{
                                marginLeft: "auto",
                                marginBottom: 0,
                                padding: "6px 12px",
                                fontWeight: 600,
                                fontSize: "14px",
                                backgroundColor: "#fff",
                                border: "1px solid #EAECF0",
                                borderRadius: "8px",
                                color: "var(--color-main)",
                                textTransform: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                              disabled={completedSessions.length === 0}
                              aria-controls="import-export-menu"
                              aria-haspopup="true"
                              onClick={(event) =>
                                setImportExportAnchorDropdownEl(
                                  event.currentTarget
                                )
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FileDownload
                                  className="my-auto"
                                  sx={{ fontSize: "16px", marginRight: "4px" }}
                                />
                                Import / Export
                              </div>
                              <KeyboardArrowDown
                                sx={{ fontSize: "20px", marginLeft: "4px" }}
                              />
                            </Button>
                            <Menu
                              id="import-export-menu"
                              anchorEl={importExportAnchorDropdownEl}
                              open={Boolean(importExportAnchorDropdownEl)}
                              onClose={() =>
                                setImportExportAnchorDropdownEl(null)
                              }
                              sx={{
                                "& .MuiPaper-root": {
                                  width: "200px", // Makes menu wider than button
                                  borderRadius: "12px", // Rounder borders
                                  marginTop: "4px",
                                },
                                "& .MuiMenuItem-root": {
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "var(--color-main)",
                                  padding: "8px 16px",
                                },
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleExport();
                                  setImportExportAnchorDropdownEl(null);
                                }}
                              >
                                <FileDownload
                                  sx={{ fontSize: "16px", marginRight: "8px" }}
                                />
                                Export
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleBulkCandidateLink();
                                  setImportExportAnchorDropdownEl(null);
                                }}
                              >
                                <FileUpload
                                  sx={{ fontSize: "16px", marginRight: "8px" }}
                                />
                                Bulk Upload
                              </MenuItem>
                            </Menu>
                            {ANALYSIS_ACCESS.includes(user.email) && (
                              <button
                                className="btn-main outlined gray d-flex"
                                disabled={
                                  runningBulk || selectedSessions.length === 0
                                }
                                style={{
                                  marginLeft: "12px",
                                  marginBottom: 0,
                                  padding: "6px 12px",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                }}
                                onClick={handleAnalyzeBulk}
                              >
                                Bulk Re-run Analysis
                              </button>
                            )}
                            <button
                              className="btn-main d-flex"
                              style={{
                                marginLeft: "12px",
                                marginBottom: 0,
                                padding: "6px 9.24px 6px 8px",
                                fontWeight: 600,
                                fontSize: "14px",
                              }}
                              onClick={handleCreateCandidateLink}
                            >
                              <Add
                                fontSize="14px"
                                className="my-auto"
                                sx={{ marginRight: "2px" }}
                              ></Add>{" "}
                              Add Candidate
                            </button>
                          </div>
                        )}
                        {!getCompletedSessionsCalled && (
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {[...Array(8)].map((_, i) => (
                                    <TableCell key={i}>
                                      <Skeleton variant="text" width={100} />
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {[...Array(5)].map((_, i) => (
                                  <TableRow key={i}>
                                    {[...Array(8)].map((_, j) => (
                                      <TableCell key={j}>
                                        <Skeleton variant="text" width={80} />
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                        {(completedSessions.length === 0 && getCompletedSessionsCalled) && (
                          <>
                            {interview.type == "template" ? (
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                width="921px"
                                style={{
                                  textAlign: "center",
                                  margin: "auto",
                                  marginTop: "50px",
                                  marginBottom: "50px",
                                }}
                              >
                                <p
                                  className="color-gray"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    marginBottom: 0,
                                  }}
                                >
                                  Templates do not use sessions.
                                </p>
                              </Box>
                            ) : (
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                width="921px"
                                style={{
                                  textAlign: "center",
                                  margin: "auto",
                                  marginTop: "50px",
                                  marginBottom: "24px",
                                }}
                              >
                                <img src={CreateSession} />
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    marginBottom: 0,
                                  }}
                                >
                                  Create your first session
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    marginTop: "20px",
                                    width: "521px",
                                  }}
                                >
                                  Take the first step towards a successful
                                  hiring process by creating a new candidate
                                  session link.
                                </p>
                                <Grid
                                  container
                                  spacing={2}
                                  style={{
                                    textAlign: "left",
                                    background: "#ECFCCB",
                                    borderRadius: "12px",
                                    width: "642px",
                                    marginTop: "25px",
                                    height: "120px",
                                    padding: "0px 15px 15px 15px",
                                  }}
                                >
                                  <Grid item xs={10}>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Automatically personalize each candidate's
                                      interview! &nbsp; 🌟
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                      }}
                                    >
                                      You can now generate specific questions
                                      based on the candidate's experience and
                                      background, making interview process more
                                      insightful.
                                    </p>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Button
                                      className="btn-main"
                                      style={{
                                        width: "92px",
                                        height: "44px",
                                        fontSize: "14px",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        marginTop: "25px",
                                      }}
                                      onClick={handleCreateCandidateLink}
                                    >
                                      Get started
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            )}
                          </>
                        )}
                        {(getCompletedSessionsCalled && filteredSessions.length > 0) && (
                          <>
                            <TableContainer style={{ overflowX: "auto" }}>
                              <Table>
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      "& .MuiTableCell-root": {
                                        lineHeight: "20px",
                                        backgroundColor: "#FAFAFA",
                                        color: "var(--color-heading)",
                                        padding: "12px 16px",
                                      },
                                    }}
                                  >
                                    {ANALYSIS_ACCESS.includes(user.email) && (
                                      <TableCell
                                        sx={{
                                          width: "42px",
                                        }}
                                      >
                                        <Checkbox
                                          style={{
                                            marginLeft: 0,
                                            paddingLeft: 0,
                                          }}
                                          checked={
                                            selectedSessions.length ===
                                            filteredSessions.filter(
                                              (session) =>
                                                session.scorecard &&
                                                Object.keys(session.scorecard)
                                                  .length > 0
                                            ).length
                                          }
                                          onClick={() => {
                                            if (
                                              selectedSessions.length <
                                              filteredSessions.length
                                            ) {
                                              const validSessions =
                                                filteredSessions.filter(
                                                  (session) =>
                                                    session.scorecard &&
                                                    Object.keys(
                                                      session.scorecard
                                                    ).length > 0
                                                );
                                              setSelectedSessions(
                                                validSessions.map(
                                                  (session) => session._id.$oid
                                                )
                                              );
                                            } else {
                                              setSelectedSessions([]);
                                            }
                                          }}
                                        />
                                      </TableCell>
                                    )}
                                    <TableCell>Candidate Name</TableCell>
                                    <TableCell>
                                      <TableSortLabel
                                        active={true}
                                        direction={statusOrder}
                                        onClick={() => handleSortStatus()}
                                      >
                                        Status
                                      </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Score</TableCell>
                                    <TableCell>
                                      <TableSortLabel
                                        active={true}
                                        direction={invitedOrder}
                                        onClick={() => handleSortInvited()}
                                      >
                                        Invited
                                      </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                      <TableSortLabel
                                        active={true}
                                        direction={completedOrder}
                                        onClick={() =>
                                          handleSortCompleted(
                                            filteredSessions,
                                            completedOrder === "asc"
                                              ? "desc"
                                              : "asc"
                                          )
                                        }
                                      >
                                        Completed
                                      </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Quick Actions</TableCell>
                                    {ANALYSIS_ACCESS.includes(user.email) ? (
                                      <TableCell>Re-run Analysis</TableCell>
                                    ) : (
                                      <TableCell></TableCell>
                                    )}
                                    {ANALYSIS_ACCESS.includes(user.email) && (
                                      <TableCell>
                                        Call Candidate's Phone
                                      </TableCell>
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {filteredSessions.map((session, idx) => {
                                    return (
                                      <TableRow
                                        className={`clickableRow ${
                                          selectedSessions.includes(
                                            session._id.$oid
                                          )
                                            ? `clickableRowSelected`
                                            : ""
                                        }`}
                                        key={idx}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          window.open(
                                            `/interviews/${interview._id}/sessions/${session._id.$oid}`,
                                            "_blank"
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {ANALYSIS_ACCESS.includes(
                                          user.email
                                        ) && (
                                          <TableCell
                                            sx={{
                                              width: "50px",
                                            }}
                                          >
                                            <Checkbox
                                              style={{
                                                marginLeft: 0,
                                                paddingLeft: 0,
                                              }}
                                              checked={selectedSessions.includes(
                                                session._id.$oid
                                              )}
                                              disabled={
                                                !session.scorecard ||
                                                Object.keys(session.scorecard)
                                                  .length === 0
                                              }
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (
                                                  session.scorecard &&
                                                  Object.keys(session.scorecard)
                                                    .length > 0
                                                ) {
                                                  toggleSessionSelected(
                                                    session._id.$oid
                                                  );
                                                }
                                              }}
                                            />
                                          </TableCell>
                                        )}
                                        <TableCell>
                                          {session.candidate_metadata && (
                                            <>
                                              {session.candidate_metadata
                                                .first_name +
                                                " " +
                                                session.candidate_metadata
                                                  .last_name}
                                            </>
                                          )}
                                          {!session.candidate_metadata && (
                                            <>N/A</>
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {session.voicemail_detected ? (
                                            <ChipTag
                                              variant="default"
                                              text="Voicemail"
                                            />
                                          ) : (
                                            <>
                                              {session.status ===
                                                SessionStatus.NOT_STARTED && (
                                                <ChipTag
                                                  variant="not-started"
                                                  text="Not Started"
                                                />
                                              )}
                                              {session.status ===
                                                SessionStatus.INCOMPLETE && (
                                                <ChipTag
                                                  variant="in-progress"
                                                  text="Incomplete"
                                                />
                                              )}
                                              {session.status ===
                                                SessionStatus.IN_PROGRESS && (
                                                <ChipTag
                                                  variant="in-progress"
                                                  text="In Progress"
                                                />
                                              )}
                                              {session.status ===
                                                SessionStatus.COMPLETED && (
                                                <ChipTag
                                                  variant="completed"
                                                  text="Completed"
                                                />
                                              )}
                                            </>
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {!session.voicemail_detected &&
                                          session.status ===
                                            SessionStatus.COMPLETED ? (
                                            !session.scorecard ||
                                            session.scorecard.length === 0 ? (
                                              <ChipTag
                                                variant="pending"
                                                text="N/A"
                                              />
                                            ) : session.customerOverrideCandidateAction ===
                                                "accept" ||
                                              passSession(session) ? (
                                              <ChipTag
                                                variant="accept"
                                                text="Accept"
                                              />
                                            ) : (
                                              <ChipTag
                                                variant="reject"
                                                text="Reject"
                                              />
                                            )
                                          ) : (
                                            <ChipTag
                                              variant="pending"
                                              text="Pending"
                                            />
                                          )}
                                          {session.potentialCheating && (
                                            <ChipTag
                                              variant="danger"
                                              text="Suspicious Activity"
                                              style={{
                                                marginLeft: "8px",
                                                backgroundColor: "#FF4D4D",
                                                color: "white",
                                              }}
                                            />
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {session.created_at.$date
                                            ? new Date(
                                                session.created_at.$date
                                              ).toLocaleString()
                                            : new Date(
                                                session.created_at * 1000
                                              ).toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                          {session.date_completed_unix_timestamp
                                            ? new Date(
                                                session.date_completed_unix_timestamp
                                              ).toLocaleString()
                                            : "N/A"}
                                        </TableCell>
                                        <TableCell>
                                          <button
                                            className="btn-icon my-auto"
                                            ref={moreActionsRef.current}
                                            style={{ margin: "auto 12px" }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleListShareToggle(e, idx);
                                            }}
                                          >
                                            <MoreVert
                                              className="color-heading m-auto"
                                              style={{
                                                fontSize: "20px",
                                              }}
                                            />
                                          </button>

                                          {/* More Actions */}
                                          <Popper
                                            open={actionsOpenIdx == idx}
                                            anchorEl={quickActionsAnchorEl}
                                            role={undefined}
                                            placement="bottom-end"
                                            transition
                                          >
                                            {({
                                              TransitionProps,
                                              placement,
                                            }) => (
                                              <Grow
                                                {...TransitionProps}
                                                style={{
                                                  transformOrigin: "right top",
                                                }}
                                              >
                                                <Paper>
                                                  <ClickAwayListener
                                                    onClickAway={
                                                      handleListActionsClose
                                                    }
                                                  >
                                                    <MenuList
                                                      autoFocusItem={
                                                        actionsOpenIdx == idx
                                                      }
                                                      id="composition-menu"
                                                      aria-labelledby="composition-button"
                                                      sx={{
                                                        ["& .MuiMenuItem-root"]:
                                                          {
                                                            fontSize: "14px",
                                                          },
                                                        ["& .MuiTypography-root"]:
                                                          {
                                                            fontSize: "14px",
                                                          },
                                                      }}
                                                      onKeyDown={
                                                        handleListKeyDown
                                                      }
                                                    >
                                                      <MenuItem
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          navigator.clipboard
                                                            .writeText(buildUniqueSessionLink(session.unique_session_id, 'web'))
                                                            .then((val) => {
                                                              setSnackbarMsg(
                                                                "Web link copied to clipboard successfully"
                                                              );
                                                              setOpenSnackbar(
                                                                true
                                                              );
                                                              setCopied(true);
                                                              handleListActionsClose();
                                                            })
                                                            .catch((err) => {
                                                              console.error(
                                                                err
                                                              );
                                                            });
                                                        }}
                                                      >
                                                        <ListItemIcon>
                                                          <Share fontSize="14px" />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                          Copy Web Link
                                                        </ListItemText>
                                                      </MenuItem>
                                                      {session.client_type &&
                                                        (session.client_type ===
                                                          "phone" ||
                                                          session.client_type ===
                                                            "mobile") && (
                                                          <MenuItem
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              navigator.clipboard
                                                                .writeText(buildUniqueSessionLink(session.unique_session_id, 'phone'))
                                                                .then((val) => {
                                                                  setSnackbarMsg(
                                                                    "Phone link copied to clipboard successfully"
                                                                  );
                                                                  setOpenSnackbar(
                                                                    true
                                                                  );
                                                                  setCopied(
                                                                    true
                                                                  );
                                                                  handleListActionsClose();
                                                                })
                                                                .catch(
                                                                  (err) => {
                                                                    console.error(
                                                                      err
                                                                    );
                                                                  }
                                                                );
                                                            }}
                                                          >
                                                            <ListItemIcon>
                                                              <Phone fontSize="14px" />
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                              Copy Phone Link
                                                            </ListItemText>
                                                          </MenuItem>
                                                        )}
                                                      <MenuItem
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          setSessionToBeDeleted(
                                                            session
                                                          );
                                                          setShowSessionDeletionConfirmation(
                                                            true
                                                          );

                                                          // close more actions menu
                                                          handleListActionsClose();
                                                        }}
                                                      >
                                                        <ListItemIcon>
                                                          <Delete fontSize="14px" />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                          Delete Session
                                                        </ListItemText>
                                                      </MenuItem>
                                                    </MenuList>
                                                  </ClickAwayListener>
                                                </Paper>
                                              </Grow>
                                            )}
                                          </Popper>
                                        </TableCell>
                                        {ANALYSIS_ACCESS.includes(user.email) &&
                                          session.call_id && (
                                            <TableCell>
                                              <button
                                                className="btn-main"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  rerunAnalysis(session, idx);
                                                }}
                                                disabled={
                                                  rerunningIdxes.includes(
                                                    idx
                                                  ) ||
                                                  rerunningIds.includes(
                                                    session._id.$oid
                                                  ) ||
                                                  session.running_summary_analysis ||
                                                  session.running_structured_outputs_analysis ||
                                                  session.running_scoring_analysis
                                                }
                                              >
                                                {rerunningIdxes.includes(idx) ||
                                                rerunningIds.includes(
                                                  session._id.$oid
                                                ) ||
                                                session.running_summary_analysis ||
                                                session.running_structured_outputs_analysis ||
                                                session.running_scoring_analysis
                                                  ? "Running..."
                                                  : "Re-run analysis"}
                                              </button>
                                            </TableCell>
                                          )}
                                        {(!ANALYSIS_ACCESS.includes(
                                          user.email
                                        ) ||
                                          !session.call_id) && (
                                          <TableCell></TableCell>
                                        )}
                                        <TableCell>
                                          {session.candidate_metadata &&
                                            session.candidate_metadata.phone &&
                                            /^\+\d{11}$/.test(
                                              session.candidate_metadata.phone
                                            ) && (
                                              <button
                                                className="btn-main"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  setSnackbarMsg(
                                                    "Calling their phone now, please wait a moment..."
                                                  );
                                                  setOpenSnackbar(true);

                                                  fetch(
                                                    `https://retell-main-proxy-69440b772c45.herokuapp.com/register-phone-call`,
                                                    {
                                                      method: "POST",
                                                      headers: {
                                                        "Content-Type":
                                                          "application/json",
                                                      },
                                                      body: JSON.stringify({
                                                        sessionId:
                                                          session.unique_session_id,
                                                      }),
                                                    }
                                                  )
                                                    .then((response) =>
                                                      response.json()
                                                    )
                                                    .then((data) => {
                                                      setSnackbarMsg(
                                                        "Called phone successfully, they will be notified shortly."
                                                      );
                                                      setOpenSnackbar(true);
                                                    })
                                                    .catch((error) => {
                                                      console.error(
                                                        "Error starting phone session:",
                                                        error
                                                      );
                                                      setSnackbarMsg(
                                                        "Error calling their phone, please try again."
                                                      );
                                                      setOpenSnackbar(true);
                                                    });
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Phone
                                                    sx={{
                                                      fontSize: 16,
                                                      marginRight: "4px",
                                                    }}
                                                  />
                                                  <span>Call</span>
                                                </div>
                                              </button>
                                            )}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            {/* SESSION DELETION */}
                            <Dialog
                              open={showSessionDeletionConfirmation}
                              onClose={() =>
                                setShowSessionDeletionConfirmation(false)
                              }
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {"Confirm Session Deletion"}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure you want to delete this session?
                                  You and the candidate will not be able to
                                  access this session anymore.
                                  {sessionToBeDeleted && (
                                    <p
                                      style={{
                                        fontSize: 16,
                                        padding: "24px",
                                        border: "0.5px solid darkgray",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <b>Candidate Name:</b>{" "}
                                      {
                                        sessionToBeDeleted.candidate_metadata
                                          .first_name
                                      }{" "}
                                      {
                                        sessionToBeDeleted.candidate_metadata
                                          .last_name
                                      }
                                      <br />
                                      <br />
                                      <b style={{ marginRight: 8 }}>Status:</b>
                                      {sessionToBeDeleted.voicemail_detected ? (
                                        <ChipTag
                                          variant="default"
                                          text="Voicemail"
                                        />
                                      ) : (
                                        <>
                                          {sessionToBeDeleted.status ===
                                            SessionStatus.NOT_STARTED && (
                                            <ChipTag
                                              variant="not-started"
                                              text="Not Started"
                                            />
                                          )}
                                          {sessionToBeDeleted.status ===
                                            SessionStatus.INCOMPLETE && (
                                            <ChipTag
                                              variant="in-progress"
                                              text="Incomplete"
                                            />
                                          )}
                                          {sessionToBeDeleted.status ===
                                            SessionStatus.IN_PROGRESS && (
                                            <ChipTag
                                              variant="in-progress"
                                              text="In Progress"
                                            />
                                          )}
                                          {sessionToBeDeleted.status ===
                                            SessionStatus.COMPLETED && (
                                            <ChipTag
                                              variant="completed"
                                              text="Completed"
                                            />
                                          )}
                                        </>
                                      )}
                                      <br />
                                      <br />
                                      <b style={{ marginRight: 8 }}>Score:</b>
                                      {!sessionToBeDeleted.voicemail_detected &&
                                      sessionToBeDeleted.status ===
                                        SessionStatus.COMPLETED ? (
                                        !sessionToBeDeleted.scorecard ||
                                        sessionToBeDeleted.scorecard.length ===
                                          0 ? (
                                          <ChipTag
                                            variant="pending"
                                            text="N/A"
                                          />
                                        ) : sessionToBeDeleted.customerOverrideCandidateAction ===
                                            "accept" ||
                                          passSession(sessionToBeDeleted) ? (
                                          <ChipTag
                                            variant="accept"
                                            text="Accept"
                                          />
                                        ) : (
                                          <ChipTag
                                            variant="reject"
                                            text="Reject"
                                          />
                                        )
                                      ) : (
                                        <ChipTag
                                          variant="pending"
                                          text="Pending"
                                        />
                                      )}
                                      {sessionToBeDeleted.potentialCheating && (
                                        <ChipTag
                                          variant="danger"
                                          text="Suspicious Activity"
                                          style={{
                                            marginLeft: "8px",
                                            backgroundColor: "#FF4D4D",
                                            color: "white",
                                          }}
                                        />
                                      )}
                                    </p>
                                  )}
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  className="btn outlined"
                                  onClick={() =>
                                    setShowSessionDeletionConfirmation(false)
                                  }
                                  color="primary"
                                >
                                  Close
                                </Button>
                                <Button
                                  className="btn outlined danger"
                                  onClick={() => {
                                    handleDeleteSession(sessionToBeDeleted);
                                    setShowSessionDeletionConfirmation(false);
                                  }}
                                  color="primary"
                                  autoFocus
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )}
                      </>
                    )}
                    {currentTab === "questions" && renderQuestions()}
                    {currentTab === "scorecard" && renderScorecard()}
                    {currentTab === "inbound-outbound" && process.env.REACT_APP_INBOUND_OUTBOUND_ENABLED === "true" && renderInboundOutbound()}
                    {currentTab === "structured-outputs" && renderOutputs()}
                    {currentTab === "settings" && renderSettings()}
                  </Box>
                ) : (
                  <Box
                    style={{
                      margin: "24px 32px",
                      borderRadius: "12px",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={128}
                      style={{ borderRadius: "12px" }}
                    />
                  </Box>
                )}
              </Box>
            </>
          )}
          {/* Simulated Interview Preview */}
          {currentTab === "questions" && (
            <div
              className="d-flex flex-col"
              style={{
                flex: 1,
                borderRadius: "12px",
                position: "fixed",
                right: "24px",
                bottom: "24px",
                height: previewOpen ? "calc(100vh - 112px)" : "70px",
                border: "1px solid lightgray",
                padding: "24px",
                background: "#fff",
                width: "400px",
              }}
            >
              {!previewOpen ? (
                <>
                  <h4 style={{ margin: 0 }}>Simulated interview preview</h4>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setCurrentPreviewQuestion(interview.questions[0]);
                      setSelectedPreview(interview.questions[0]);
                      setPreviewOpen(true);
                    }}
                    sx={{
                      position: "absolute",
                      right: 16,
                      top: 20,
                    }}
                  >
                    <OpenInFull style={{ fontSize: "16px" }} />
                  </IconButton>
                </>
              ) : (
                <>
                  <div className="d-flex flex-row">
                    <h3 className="section-heading" style={{ marginTop: 0 }}>
                      Simulated interview preview
                    </h3>
                    <IconButton
                      aria-label="close"
                      onClick={() => setPreviewOpen(false)}
                      sx={{
                        position: "absolute",
                        right: 16,
                        top: 16,
                      }}
                    >
                      <CloseFullscreen style={{ fontSize: "16px" }} />
                    </IconButton>
                  </div>
                  <p
                    className="color-gray section"
                    style={{ marginTop: 0, fontSize: "14px" }}
                  >
                    The simulated interview below outlines what a potential
                    interaction might look like, based on the topic and depth
                    you've selected/created.
                  </p>

                  <FormSelect
                    label="Question"
                    variant="outlined"
                    value={currentPreviewQuestion.question}
                    onChange={(e) => {
                      let text = e.target.value;
                      let sel = interview.questions.find(
                        (question) => question.question == text
                      );
                      setSelectedPreview(sel);
                    }}
                  >
                    {interview.questions.map((question) => {
                      return (
                        <MenuItem key={question.id} value={question.question}>
                          {truncate(question.question)}
                        </MenuItem>
                      );
                    })}
                  </FormSelect>
                  <div style={{ overflow: "auto" }}>
                    <SimulatedPreview
                      loading={loadingPreview}
                      preview={interviewPreview}
                    ></SimulatedPreview>
                  </div>
                </>
              )}
            </div>
          )}
        </Box>
        <Dialog open={showDeleteModal}>
          <DialogTitle>Delete {interview.title}</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this interview configuration?
          </DialogContent>
          <DialogActions>
            <button
              onClick={() => setShowDeleteModal(false)}
              className="btn-main outlined"
            >
              No, Cancel
            </button>
            <button
              onClick={() => deleteInterviewConfiguration()}
              className="btn-main danger outlined"
            >
              Yes, Delete!
            </button>
          </DialogActions>
        </Dialog>
        <Dialog open={showSummary}>
          <DialogTitle>Summary</DialogTitle>
          <DialogContent>
            <p>{currentSummary}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowSummary(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showStructuredOutputs}>
          <DialogTitle>Structured Outputs</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentStructuredOutputs && (
                  <pre>{JSON.stringify(currentStructuredOutputs, null, 2)}</pre>
                )}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowStructuredOutputs(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showVideoRecording}>
          <DialogTitle>Video Recording</DialogTitle>
          <DialogContent>
            <video src={""}></video>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowVideoRecording(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showMessages}>
          <DialogTitle>Transcript</DialogTitle>
          <DialogContent>
            <Grid container>
              {currentMessages.map((msg) => {
                return (
                  <Grid item xs={12}>
                    <p>
                      {msg.message.role === "assistant" ? (
                        <b>Wayfaster</b>
                      ) : (
                        <b>Candidate</b>
                      )}{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      {new Date(
                        (msg.createdAt - currentMessages[0].createdAt) * 1000
                      )
                        .toISOString()
                        .substr(11, 8)}
                    </p>
                    <p>{msg.message.content}</p>
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowMessages(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showCandidateLinkCreation}
          onClose={() => {
            setCopied(false);
            setShowCandidateLinkCreation(false);
            setGenerateSessionQuestions([]);
            setUniqueSessionLink("");
            setCreateSessionInputs({
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              callType: "web",
            });
            setCreateCandidateSessionActiveStep(0);
            setSessionSpecificContext("");
            setSessionSpecificQuestionsStarted(false);
          }}
        >
          <DialogTitle style={{ borderBottom: "0.5px solid lightgray" }}>
            <Stepper activeStep={createCandidateSessionActiveStep}>
              {createCandidateSessionSteps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isCreateCandidateSessionStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isCreateCandidateSessionStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      StepIconComponent={() => {
                        return (
                          <div
                            style={{
                              backgroundColor:
                                createCandidateSessionActiveStep >= index
                                  ? "black"
                                  : "lightgray",
                              color: "white",
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {index + 1}
                          </div>
                        );
                      }}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </DialogTitle>
          <DialogContent style={{ maxWidth: "600px" }}>
            <div style={{ marginTop: 25, maxWidth: "600px" }}>
              {renderActiveStepComponent()}
            </div>
          </DialogContent>
          <DialogActions>
            {createCandidateSessionActiveStep === 0 &&
              generatedSessionQuestions.length > 0 && (
                <button
                  className="btn-main"
                  disabled={generateSessionSpecificQuestionsLoading}
                  onClick={() => {
                    setCreateCandidateSessionActiveStep(
                      createCandidateSessionActiveStep + 1
                    );
                  }}
                >
                  Next
                </button>
              )}
          </DialogActions>
        </Dialog>

        <Dialog
          open={showBulkCandidateLinkCreation}
          maxWidth="lg"
          onClose={() => {
            setCopied(false);
            setShowBulkCandidateLinkCreation(false);
            setUniqueSessionLink("");
            setCreateSessionInputs({
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              callType: "web",
            });
            setCreateBulkCandidateSessionActiveStep(0);
          }}
        >
          <DialogTitle style={{ borderBottom: "0.5px solid lightgray" }}>
            <Stepper activeStep={createBulkCandidateSessionActiveStep}>
              {createBulkCandidateSessionSteps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      StepIconComponent={() => {
                        return (
                          <div
                            style={{
                              backgroundColor:
                                createCandidateSessionActiveStep >= index
                                  ? "black"
                                  : "lightgray",
                              color: "white",
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {index + 1}
                          </div>
                        );
                      }}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </DialogTitle>
          <DialogContent>
            <div style={{ marginTop: 25 }}>
              {renderActiveBulkStepComponent()}
            </div>
          </DialogContent>
          <DialogActions>
            {createCandidateSessionActiveStep === 0 &&
              generatedSessionQuestions.length > 0 && (
                <button
                  className="btn-main"
                  disabled={generateSessionSpecificQuestionsLoading}
                  onClick={() => {
                    setCreateCandidateSessionActiveStep(
                      createCandidateSessionActiveStep + 1
                    );
                  }}
                >
                  Next
                </button>
              )}
          </DialogActions>
        </Dialog>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setOpenSnackbar(false);
          }}
          message={snackbarMsg}
        />
        <Dialog open={showScorecard}>
          <DialogTitle>Scorecard</DialogTitle>
          <DialogContent>
            {currentScorecards.length > 0 && renderScorecards()}
            {currentScorecard.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Skill</TableCell>
                    <TableCell>Feedback</TableCell>
                    <TableCell>Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentScorecard.map((scorecardItem, index) => (
                    <TableRow key={index}>
                      <TableCell>{scorecardItem.signal}</TableCell>
                      <TableCell>{scorecardItem.feedback}</TableCell>
                      <TableCell>{scorecardItem.score}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </DialogContent>
          <DialogActions>
            <button
              className="btn-main"
              onClick={() => setShowScorecard(false)}
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </DndContext>
  );
}

export default InterviewDetail;
